<template>
  <ion-card class="mx-0 my-0" @click="onTapNotification">
    <div class="d-flex justify-space-between mx-3 mt-2">
      <div class="d-flex">
        <ion-icon :icon="notifOptions[0]?.icon" color="primary"></ion-icon>
        <ion-label class="fs-1 ml-1">{{ notifOptions[0]?.label }}</ion-label>
      </div>
      <div class="d-flex ion-align-items-center">
        <ion-label class="fs-1 mr-1">{{ displayDateAndTime(data.created_at) }}</ion-label>
        <div class="d-flex justify-end" v-if="!isReadSingleNotif">
          <div class="out-round">
            <div class="in-round" />
          </div>
        </div>
      </div>
    </div>
    <ion-item class="ion-align-items-start" lines="none">
      <div class="mr-2" slot="start" style="margin-top: 10px">
        <div
          v-if="
            (imagePath() === IMAGE_PATH.CUSTOMER && !isValidThumbnail(data.thumbnail_image)) ||
            (imagePath() === IMAGE_PATH.CUSTOMER && isErrorRenderImageCustomer)
          "
        >
          <div
            class="image-default"
            :style="`background: ${handleRandomBackground().background}; color:${
              handleRandomBackground().color
            }`"
          >
            {{ customerName }}
          </div>
        </div>
        <td-image
          v-else
          style="width: 48px; height: 48px"
          alt="thumbnail"
          :image="data?.thumbnail_image ?? ``"
          :imagePath="imagePath()"
          @errorLoadImageCustomer="() => (isErrorRenderImageCustomer = true)"
        ></td-image>
      </div>
      <ion-label>
        <ion-text class="fw-600 fs-2"> {{ data?.title || '-' }} </ion-text>
        <div class="mt-1">
          <ion-note color="medium" class="ion-text-wrap fw-400 fs-2 mt-2">
            {{ data?.message || '-' }}
          </ion-note>
        </div>
        <br />
        <ion-button class="mt-1 text-capitalize" expand="block" fill="outline">{{
          $t('view_details')
        }}</ion-button>
      </ion-label>
    </ion-item>
  </ion-card>
</template>
<script>
import { useCardNotification } from '@/usecases/notifications';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'CardNotification',
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const {
      imagePath,
      displayDateAndTime,
      notifOptions,
      IMAGE_PATH,
      isReadSingleNotif,
      onTapNotification,
      SUB_TYPE_NOTIFICATIONS,
      renderInitialCustomerNameThumbnail,
      isValidThumbnail
    } = useCardNotification(props);
    const { customerName, handleRandomBackground } = renderInitialCustomerNameThumbnail();
    const isErrorRenderImageCustomer = ref(false);
    return {
      customerName,
      isErrorRenderImageCustomer,
      isValidThumbnail,
      isReadSingleNotif,
      handleRandomBackground,
      SUB_TYPE_NOTIFICATIONS,
      onTapNotification,
      imagePath,
      displayDateAndTime,
      notifOptions,
      IMAGE_PATH
    };
  }
});
</script>
<style lang="scss" scoped>
.image-default {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14.4px;
  line-height: 17px;
}
.unread-icon {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: var(--ion-color-danger);
}
.out-round {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: #ffd7d7;
  position: relative;
}
.in-round {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background-color: var(--ion-color-danger);
  position: absolute;
  top: 19%;
  left: 20%;
}
</style>
