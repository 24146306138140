<template>
  <ion-badge>
    <slot></slot>
  </ion-badge>
</template>
<script>
import { defineComponent } from 'vue';
export default defineComponent({});
</script>
<style lang="scss" scoped>
ion-badge {
  padding: 4px 8px;
  font-size: 14px;
  border-radius: 100px;
  font-weight: normal;
  color: white;
}
</style>
