<template>
  <ion-card class="mx-0 my-0" v-for="i in 10" :key="i">
    <div class="d-flex justify-space-between mx-3 mt-2">
      <div class="d-flex">
        <ion-skeleton-text
          :animated="true"
          style="width: 5rem; height: 17px; border-radius: 2px; margin-right: 5px"
        ></ion-skeleton-text>
      </div>
      <div class="d-flex ion-align-items-center">
        <ion-skeleton-text
          :animated="true"
          style="width: 60px; height: 17px; border-radius: 2px; margin-right: 10px"
        ></ion-skeleton-text>
        <div class="d-flex justify-end">
          <div class="out-round">
            <div class="in-round" />
          </div>
        </div>
      </div>
    </div>
    <ion-item class="ion-align-items-start" lines="none">
      <div class="mr-2" slot="start" style="margin-top: 10px">
        <ion-skeleton-text
          :animated="true"
          style="width: 48px; height: 48px; border-radius: 10%"
        ></ion-skeleton-text>
      </div>
      <ion-label>
        <ion-skeleton-text
          :animated="true"
          style="width: 80%; height: 17px; margin-bottom: 5px; border-radius: 5px"
        ></ion-skeleton-text>
        <ion-skeleton-text
          :animated="true"
          style="width: 90%; height: 17px; margin-bottom: 5px; border-radius: 5px"
        ></ion-skeleton-text>
        <ion-skeleton-text
          :animated="true"
          style="width: 70%; height: 17px; margin-bottom: 5px; border-radius: 5px"
        ></ion-skeleton-text>
        <ion-skeleton-text
          :animated="true"
          style="width: 80%; height: 17px; margin-bottom: 5px; border-radius: 5px"
        ></ion-skeleton-text>
        <ion-skeleton-text
          :animated="true"
          style="width: 100%; height: 17px; margin-bottom: 5px; border-radius: 5px"
        ></ion-skeleton-text>
      </ion-label>
    </ion-item>
  </ion-card>
</template>

<script>
export default {
  name: 'SkeletonNotifications'
};
</script>
