import { ACTIONS } from '@/modules/b2b/store/notification/actions';
import { QUOTATION_STATUSSES } from '@/modules/shared/constants';
import { useBackButton } from '@ionic/vue';
import { chevronBackOutline } from 'ionicons/icons';
import { computed, onMounted, ref, watch } from 'vue';
import { useConfig, useGetStorageData } from '..';

const usePendingQuotationList = () => {
  const { store, storePath, isMerchantUser } = useConfig();
  const { selectedUser } = useGetStorageData();
  const STORE_MODULE_PATH = `${storePath}/notification`;
  const quotationPendingList = ref([]);
  const isLoading = ref(false);
  const hasBeenFetchPendingQuotation = ref(false);
  const isDisabledPendingList = ref(false);
  const reRenderCardPendingQuotation = ref(1);
  const data = computed(() => store.getters[`${STORE_MODULE_PATH}/quotationsPending`]);
  const quotationUpdated = computed(() => store.getters[`${STORE_MODULE_PATH}/saleApproveQuotation`]);
  const fetchPendingQuotation = async () => {
    isLoading.value = true;
    const { tenant } = await selectedUser.value;
    await store.dispatch(`${STORE_MODULE_PATH}/${ACTIONS.GET_PENDING_QUOTATION_LIST}`, {
      params: {
        ...(!isMerchantUser ? { tenantId: tenant.id } : {}),
        offset: 0,
        isIncludeTotalCount: true,
        limit: 20
      }
    });
    quotationPendingList.value = data.value;
    hasBeenFetchPendingQuotation.value = true;
    isLoading.value = false;
  };

  const loadMorePending = async (e) => {
    const { tenant } = await selectedUser.value;
    if (data.value.length < 20) {
      isDisabledPendingList.value = true;
      return;
    }
    await store.dispatch(`${STORE_MODULE_PATH}/${ACTIONS.GET_PENDING_QUOTATION_LIST}`, {
      params: {
        ...(!isMerchantUser ? { tenantId: tenant.id } : {}),
        offset: quotationPendingList.value.length,
        isIncludeTotalCount: true,
        limit: 20
      }
    });
    quotationPendingList.value = [...quotationPendingList.value, ...data.value];
    e.target.complete();
  };

  watch(quotationUpdated, (newValue) => {
    quotationPendingList.value = quotationPendingList.value.filter((item) => item.id != newValue.quotationId);
    reRenderCardPendingQuotation.value++;
  });

  return {
    reRenderCardPendingQuotation,
    loadMorePending,
    isLoadingPending: isLoading,
    isDisabledPendingList,
    fetchPendingQuotation,
    quotationPendingList,
    hasBeenFetchPendingQuotation
  };
};

const useApprovedQuotationList = () => {
  const { store, storePath, isMerchantUser } = useConfig();
  const STORE_MODULE_PATH = `${storePath}/notification`;
  const { selectedUser } = useGetStorageData();
  const quotationApprovedList = ref([]);
  const isLoading = ref(false);
  const hasBeenFetchApprovedQuotation = ref(false);
  const isDisabledApprovedList = ref(false);
  const data = computed(() => store.getters[`${STORE_MODULE_PATH}/quotationsApproved`]);
  const fetchApprovedQuotation = async () => {
    isLoading.value = true;
    const { tenant } = await selectedUser.value;
    await store.dispatch(`${STORE_MODULE_PATH}/${ACTIONS.GET_APPROVED_QUOTATION_LIST}`, {
      params: {
        ...(!isMerchantUser ? { tenantId: tenant.id } : {}),
        offset: 0,
        isIncludeTotalCount: true,
        limit: 20
      }
    });
    quotationApprovedList.value = data.value;
    hasBeenFetchApprovedQuotation.value = true;
    isLoading.value = false;
  };
  const loadMoreApproved = async (e) => {
    const { tenant } = await selectedUser.value;
    if (data.value.length < 20) {
      isDisabledApprovedList.value = true;
      return;
    }
    await store.dispatch(`${STORE_MODULE_PATH}/${ACTIONS.GET_APPROVED_QUOTATION_LIST}`, {
      params: {
        ...(!isMerchantUser ? { tenantId: tenant.id } : {}),
        offset: quotationApprovedList.value.length,
        isIncludeTotalCount: true,
        limit: 20
      }
    });
    quotationApprovedList.value = [...quotationApprovedList.value, ...data.value];
    e.target.complete();
  };

  return {
    isDisabledApprovedList,
    isLoadingApproved: isLoading,
    loadMoreApproved,
    fetchApprovedQuotation,
    hasBeenFetchApprovedQuotation,
    quotationApprovedList
  };
};

const useRejectedQuotationList = () => {
  const { store, storePath, isMerchantUser } = useConfig();
  const STORE_MODULE_PATH = `${storePath}/notification`;
  const { selectedUser } = useGetStorageData();
  const quotationRejectedList = ref([]);
  const isLoading = ref(false);
  const hasBeenFetchRejectedQuotation = ref(false);
  const isDisabledRejectedList = ref(false);
  const data = computed(() => store.getters[`${STORE_MODULE_PATH}/quotationsRejected`]);
  const fetchRejectedQuotation = async () => {
    isLoading.value = true;
    const { tenant } = await selectedUser.value;
    await store.dispatch(`${STORE_MODULE_PATH}/${ACTIONS.GET_REJECTED_QUOTATION_LIST}`, {
      params: {
        ...(!isMerchantUser ? { tenantId: tenant.id } : {}),
        offset: 0,
        isIncludeTotalCount: true,
        limit: 20
      }
    });
    quotationRejectedList.value = data.value;
    hasBeenFetchRejectedQuotation.value = true;
    isLoading.value = false;
  };

  const loadMoreRejected = async (e) => {
    const { tenant } = await selectedUser.value;
    if (data.value.length < 20) {
      isDisabledRejectedList.value = true;
      return;
    }
    await store.dispatch(`${STORE_MODULE_PATH}/${ACTIONS.GET_REJECTED_QUOTATION_LIST}`, {
      params: {
        ...(!isMerchantUser ? { tenantId: tenant.id } : {}),
        offset: quotationRejectedList.value.length,
        isIncludeTotalCount: true,
        limit: 20
      }
    });
    quotationRejectedList.value = [...quotationRejectedList.value, ...data.value];
    e.target.complete();
  };

  return {
    loadMoreRejected,
    isLoadingRejected: isLoading,
    isDisabledRejectedList,
    fetchRejectedQuotation,
    hasBeenFetchRejectedQuotation,
    quotationRejectedList
  };
};

const useQuotationListNotification = () => {
  const { t, router, store, storePath, isMerchantUser } = useConfig();
  const STORE_MODULE_PATH = `${storePath}/notification`;

  const {
    fetchPendingQuotation,
    loadMorePending,
    isDisabledPendingList,
    isLoadingPending,
    quotationPendingList,
    hasBeenFetchPendingQuotation,
    reRenderCardPendingQuotation
  } = usePendingQuotationList();
  const {
    isDisabledApprovedList,
    loadMoreApproved,
    isLoadingApproved,
    fetchApprovedQuotation,
    quotationApprovedList,
    hasBeenFetchApprovedQuotation
  } = useApprovedQuotationList();
  const {
    loadMoreRejected,
    isDisabledRejectedList,
    isLoadingRejected,
    fetchRejectedQuotation,
    quotationRejectedList,
    hasBeenFetchRejectedQuotation
  } = useRejectedQuotationList();

  const selectedSegment = ref(QUOTATION_STATUSSES.ALL);
  const isDisabledLoadMore = ref(false);
  const isLoading = ref(false);
  const allQuotations = ref([]);
  const badgeHeader = ref(null);
  const setBadgeHeaderCount = (state) => (badgeHeader.value = state);
  const totalCountNotifications = computed(() => store.getters[`${STORE_MODULE_PATH}/totalCount`]);
  const allNotifications = computed(() => store.getters[`${STORE_MODULE_PATH}/notificationsLists`]);
  const notificationBadges = computed(() => store.getters[`${STORE_MODULE_PATH}/notificationsBadges`]);
  const segmentOptions = [
    {
      label: t('all'),
      value: QUOTATION_STATUSSES.ALL
    },
    !isMerchantUser && {
      label: t('pending'),
      value: QUOTATION_STATUSSES.PENDING
    },
    {
      label: t('approved'),
      value: QUOTATION_STATUSSES.APPROVED
    },
    {
      label: t('rejected'),
      value: QUOTATION_STATUSSES.REJECTED
    }
  ].filter(Boolean);

  useBackButton(10, () => {
    router.back();
  });

  const fetchData = async () => {
    isLoading.value = true;
    await store.dispatch(`${STORE_MODULE_PATH}/${ACTIONS.GET_NOTIFICATIONS}`, {
      params: {
        offset: 0,
        isIncludeTotalCount: true,
        notificationCategory: 'QUOTATION'
      }
    });
    setBadgeHeaderCount(notificationBadges.value.quotationCount);
    allQuotations.value = allNotifications.value.data;
    isLoading.value = false;
  };

  const loadMore = async (e) => {
    if (totalCountNotifications.value === allNotifications.value.data.length) {
      isDisabledLoadMore.value = true;
      return;
    }
    await store.dispatch(`${STORE_MODULE_PATH}/${ACTIONS.LOAD_MORE_NOTIFICATION}`, {
      params: {
        offset: allNotifications.value.data.length,
        isIncludeTotalCount: true,
        notificationCategory: 'QUOTATION'
      },
      onFinish: async (data) => {
        allQuotations.value = [...allQuotations.value, ...data.data];
        e.target.complete();
      }
    });
  };

  watch(
    notificationBadges,
    (newValue) => {
      const { infoCount, orderCount, productCount, quotationCount } = newValue;
      if (!infoCount && !orderCount && !productCount && !quotationCount) {
        allQuotations.value = allQuotations.value.map((notif) => {
          return {
            ...notif,
            read: true
          };
        });
      }
      setBadgeHeaderCount(newValue.quotationCount);
    },
    {
      deep: true
    }
  );

  watch(selectedSegment, async (newValue) => {
    if (newValue === QUOTATION_STATUSSES.PENDING) {
      if (hasBeenFetchPendingQuotation.value) return;
      isLoading.value = true;
      await fetchPendingQuotation();
      isLoading.value = false;
    } else if (newValue === QUOTATION_STATUSSES.APPROVED) {
      if (hasBeenFetchApprovedQuotation.value) return;
      isLoading.value = true;
      await fetchApprovedQuotation();
      isLoading.value = false;
    } else if (newValue === QUOTATION_STATUSSES.REJECTED) {
      if (hasBeenFetchRejectedQuotation.value) return;
      isLoading.value = true;
      await fetchRejectedQuotation();
      isLoading.value = false;
    }
  });

  onMounted(() => {
    fetchData();
  });

  return {
    isLoadingApproved,
    isLoadingPending,
    isLoadingRejected,
    fetchPendingQuotation,
    fetchApprovedQuotation,
    reRenderCardPendingQuotation,
    fetchRejectedQuotation,
    quotationPendingList,
    quotationApprovedList,
    quotationRejectedList,
    allQuotations,
    isDisabledPendingList,
    loadMoreRejected,
    isDisabledRejectedList,
    loadMoreApproved,
    isDisabledApprovedList,
    segmentOptions,
    loadMore,
    loadMorePending,
    isLoading,
    selectedSegment,
    isDisabledLoadMore,
    fetchData,
    chevronBackOutline,
    badgeHeader
  };
};

export { useQuotationListNotification };
