<template>
  <ion-grid>
    <ion-row>
      <ion-col size="3">
        <custom-img
          style="width: 66px; height: auto"
          :image="item.image"
          :imagePath="IMAGE_PATH.PRODUCT"
          :imageDefault="DEFAULT_PRODUCT_IMAGE"
        />
      </ion-col>
      <ion-col size="9">
        <ion-row class="d-flex flex-column">
          <div>
            <ion-text class="ion-text-uppercase ion-no-margin" color="text-primary"
              ><h6>{{ item.tenant.tenant.name }}</h6></ion-text
            >
          </div>
          <div>
            <ion-text
              ><h5>{{ item.sku_name }}</h5></ion-text
            >
          </div>
          <div>
            <badge v-if="item.halal" color="badge-success"
              ><ion-text>{{ $t('sync_state.halal') }}</ion-text></badge
            >
            <badge v-else-if="item.halal === false" color="badge-warning"
              ><ion-text>{{ $t('sync_state.non_halal') }}</ion-text></badge
            >
          </div>
        </ion-row>
        <ion-row class="mt-2">
          <ion-col size="7" class="ion-no-padding">
            <ion-text class="ion-text-uppercase" color="text-medium">
              <h6>{{ $t('packaging_size') }}</h6>
            </ion-text>
            <ion-text>{{ packagingSize }}</ion-text>
          </ion-col>
          <ion-col size="5" class="ion-no-padding">
            <ion-text class="ion-text-uppercase" color="text-medium"
              ><h6>{{ $t('weight') }}</h6></ion-text
            >
            <ion-text v-if="!item?.is_order_by_weight"
              >{{ `${item.weight} ${DEFAULT_WEIGHT_UOM}` }}
            </ion-text>
            <tooltip v-if="item?.is_catch_weight" />
            <ion-text v-if="item?.is_order_by_weight">{{ $t('order_by_weight') }}</ion-text>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>
<script>
import Badge from '@/components/atoms/badge/Badge.vue';
import CustomImg from '@/components/atoms/image/Image.vue';
import Tooltip from '@/components/molecules/tooltip/Tooltip.vue';
import { DEFAULT_PRODUCT_IMAGE, IMAGE_PATH } from '@/modules/b2b/constants';
import { DEFAULT_WEIGHT_UOM } from '@/modules/shared/constants/';
import { useCheck } from '@/modules/shared/utils/';
import { computed, defineComponent } from 'vue';
export default defineComponent({
  components: {
    Badge,
    CustomImg,
    Tooltip
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const { checkWeightItem, combineTwoUnit, isItemKGAndNoneUnit } = useCheck();
    const packagingSize = computed(() => {
      const { unit_per_oom, uom, unit_per_item, unit_per_item_uom } = props.item;
      if (isItemKGAndNoneUnit(uom, unit_per_item)) {
        return '';
      }
      const itemsInProduct = checkWeightItem(unit_per_oom, uom);
      const unitsInItem = checkWeightItem(unit_per_item, unit_per_item_uom);
      return combineTwoUnit(itemsInProduct, unitsInItem);
    });
    return {
      IMAGE_PATH,
      DEFAULT_WEIGHT_UOM,
      packagingSize,
      DEFAULT_PRODUCT_IMAGE
    };
  }
});
</script>
<style lang="scss" scoped>
h6,
h5 {
  margin: 0px;
  margin-bottom: 8px;
}
h6 {
  font-size: 12px;
  font-weight: 700;
}
h5 {
  font-size: 16px;
}
</style>
