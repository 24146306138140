<template>
  <div :class="{ 'padding-horizontal': paddingHorizontal, 'padding-vertical': paddingVertical }">
    <slot> </slot>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    paddingHorizontal: {
      type: Boolean,
      required: false
    },
    paddingVertical: {
      type: Boolean,
      required: false
    }
  }
});
</script>
<style lang="scss" scoped>
.padding-horizontal {
  padding-left: 8px;
  padding-right: 8px;
}
.padding-vertical {
  padding-top: 8px;
  padding-bottom: 8px;
}
</style>
