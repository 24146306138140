<template>
  <ion-button>
    <slot></slot>
  </ion-button>
</template>
<script>
import { defineComponent } from 'vue';
export default defineComponent({});
</script>
<style lang="scss" scoped>
ion-button {
  --border-radius: 10px;
}
</style>
