import { ACTIONS } from '@/modules/sale/store/notification/actions';
import { NUMBER_OF_DECIMAL_PLACES } from '@/modules/shared/constants/common';
import { useAlert, useLoading } from '@/modules/shared/utils/';
import { computed, onMounted, ref } from 'vue';
import { useConfig } from '..';

export const NOTIFICATION_SALE_MODULES = 'sale/notification';
export const NOTIFICATION_SALE_ITEM_MODULES = 'sale/notification/item';

export const getName = (firstName, lastName) => {
  if (firstName && lastName) {
    return firstName + ' ' + lastName;
  }
  return firstName + ' ' + lastName;
};
export const useCheckElement = () => {
  const unitIsNull = (number) => number ?? 0;
  return { unitIsNull };
};

export function useModelWrapper(props, emit) {
  return computed({
    get: () => props.modelValue,
    set: (value) => emit('update:modelValue', value)
  });
}

export function assignObject(target, source) {
  Object.keys(target).map((key) => {
    const element = source[key];
    if (element) {
      target[key] = element;
    }
  });
  return target;
}
export function checkMergeData(objValue, srcValue) {
  if (srcValue && typeof objValue === 'object') {
    return assignObject(objValue, srcValue);
  }
  return srcValue ?? objValue;
}

const item = ref({
  sku_name: 'no name',
  halal: false,
  is_order_by_weight: false,
  is_catch_weight: false,
  image: '',
  quotation_status: 'requested',
  created_at: '',
  price: 0,
  priced_by: '',
  tenant: {
    tenant: {
      name: ''
    }
  },
  weight: 0,
  unit_per_oom: 0,
  uom: '',
  origin_price: 0,
  requestor: {
    first_name: '',
    last_name: '',
    image: ''
  }
});

export const useLoadData = (quotationId) => {
  const { store } = useConfig();
  const { showLoading } = useLoading();
  const getUserName = ref(getName(item.value.requestor.first_name, item.value.requestor.last_name));
  const uomData = ref('');
  const pricedBy = ref('');
  const isOrderByWeight = ref(false);
  const isCatchWeight = ref(false);
  const isCallFinish = ref(false);
  onMounted(async () => {
    await showLoading(() =>
      store
        .dispatch(`${NOTIFICATION_SALE_MODULES}/${ACTIONS.GET_VIEWS_QUOTATIONS_DETAIL}`, { id: quotationId })
        .then(() => {
          Object.assign(item.value, store.getters[NOTIFICATION_SALE_ITEM_MODULES]);
          getUserName.value = getName(item.value.requestor.first_name, item.value.requestor.last_name);
          uomData.value = item.value.uom;
          pricedBy.value = item.value.priced_by;
          isOrderByWeight.value = item.value.is_order_by_weight;
          isCatchWeight.value = item.value.is_catch_weight;
          isCallFinish.value = true;
        })
    );
  });

  return {
    item,
    getUserName,
    isCatchWeight,
    isOrderByWeight,
    uomData,
    pricedBy,
    isCallFinish
  };
};

export const addCurrencySymbol = (currencySymbol, price) => {
  return currencySymbol + ' ' + price;
};

export const calculatePriceOnWeight = (price, weight) => {
  return Number(price) / Number(weight);
};

export const calculatePriceWithWeight = (price, weight) => {
  return Number(price) * Number(weight);
};

export const convertStringToDecimal = (number) => {
  return +Number(number).toFixed(NUMBER_OF_DECIMAL_PLACES);
};

export const useSendUpdatePrice = () => {
  const { t, store } = useConfig();
  const { createAlert } = useAlert();
  const { showLoading } = useLoading();
  const submitUpdatePrice = async ({ id, price, limitPrice, unitPrice }, dismissModal) => {
    const payload = {
      actionType: 0,
      quotationId: id,
      unitPrice: convertStringToDecimal(unitPrice),
      price: convertStringToDecimal(price),
      approve: 1
    };
    const afterLoading = async () => {
      return Promise.all([dismissModal()]);
    };
    showLoading(() =>
      store
        .dispatch(`${NOTIFICATION_SALE_MODULES}/${ACTIONS.SALE_APPROVE_QUOTATION}`, payload)
        .then(async () => {
          if (isPriceGreaterLimitPrice(price, limitPrice)) {
            createAlert(t('success'), t('price_is_quoted'), afterLoading);
          } else {
            createAlert(t('quotationPendingTitle'), t('quotationPendingContent'), afterLoading);
          }
        })
        .catch((error) => {
          createAlert(t('request_reject'), error.message, dismissModal);
        })
    );
  };
  return { submitUpdatePrice };
};

export const useSendRejectQuotation = () => {
  const { t, store } = useConfig();
  const { createAlert } = useAlert();
  const { showLoading } = useLoading();
  const submitRejectQuotation = async ({ id }, dismissModal) => {
    const payload = {
      quotationId: id
    };
    const afterLoading = async () => {
      return Promise.all([dismissModal()]);
    };
    showLoading(() =>
      store
        .dispatch(`${NOTIFICATION_SALE_MODULES}/${ACTIONS.SALE_REJECT_QUOTATION}`, payload)
        .then(async () => {
          createAlert(t('success'), t('request_reject'), afterLoading);
        })
        .catch((error) => {
          createAlert(t('request_reject'), error.message, dismissModal);
        })
    );
  };
  return { submitRejectQuotation };
};

export const colorList = {
  primary: 'button-primary',
  warning: 'button-warning'
};

export const textList = {
  primary: 'update_price',
  warning: 'request_approval'
};

const isPriceGreaterLimitPrice = (price, limitPrice) => {
  // fix for rounding incorrect
  price = parseFloat(Math.round(Number(price) * 100) / 100).toFixed(2);
  limitPrice = parseFloat(Math.round(Number(limitPrice) * 100) / 100).toFixed(2);
  return Number(price) >= Number(limitPrice);
};
const checkButtonColorUpdate = (price, limitPrice) => {
  if (isPriceGreaterLimitPrice(price, limitPrice)) {
    return 'button-primary';
  }
  return 'button-warning';
};

const checkButtonTextUpdate = (price, limitPrice) => {
  return isPriceGreaterLimitPrice(price, limitPrice) ? textList.primary : textList.warning;
};

const checkDisableSubmit = (price) => price <= 0;

export const useChangePrice = () => {
  const price = ref(0);
  const unitPrice = ref(0);
  const color = ref(colorList.primary);
  const text = ref(textList.primary);
  const isDisabledSubmit = ref(true);
  const { unitIsNull } = useCheckElement();
  const updatePrice = ({ value, weight, limitPrice }) => {
    unitPrice.value = unitIsNull(value);
    price.value = calculatePriceWithWeight(unitIsNull(value), unitIsNull(weight));
    color.value = checkButtonColorUpdate(price.value, limitPrice);
    text.value = checkButtonTextUpdate(price.value, limitPrice);
    isDisabledSubmit.value = checkDisableSubmit(price.value);
  };

  return {
    price,
    isDisabledSubmit,
    updatePrice,
    color,
    text,
    unitPrice
  };
};
