<template>
  <ion-page>
    <ion-header>
      <ion-toolbar class="toolbar-header">
        <div class="d-flex align-center justify-space-between">
          <ion-label class="fw-500 fs-3 pl-2">{{ $t('requestQuotationDetails') }}</ion-label>
          <ion-icon @click="closeModal" class="text-gray-500 fs-5 pr-2" :icon="closeCircleOutline"></ion-icon>
        </div>
      </ion-toolbar>
    </ion-header>

    <ion-content v-if="!isOpenLoadingref">
      <ion-list lines="none">
        <ion-item class="pl-2 mt-2 mb-3">
          <ion-avatar slot="start" style="width: auto; height: auto">
            <td-image
              key="user"
              style="width: 48px; height: 48px"
              alt="user"
              :image="quotationDetailData?.requestor?.image"
              :imagePath="IMAGE_PATH.USER"
              :imageDefault="DEFAULT_USER_AVARTAR"
            ></td-image>
          </ion-avatar>
          <ion-label>
            <h3 class="fs-2">
              {{ quotationDetailData?.requestor?.first_name }}
              {{ quotationDetailData?.requestor?.last_name || '' }}
            </h3>
            <div class="d-flex mt-1">
              <ion-icon :icon="homeOutline"></ion-icon>
              <span class="fs-2 ml-1 grey-color" style="margin-top: 2px">{{
                quotationDetailData?.customer_name
              }}</span>
            </div>
          </ion-label>
        </ion-item>
      </ion-list>
      <div class="d-flex justify-space-between mx-5 align-center">
        <div>
          <div class="fw-600 fs-2">{{ $t('requestAt') }}</div>
          <div class="d-flex mt-1">
            <ion-text class="ion-no-padding fs-12 grey-color">{{
              formatDateTime(quotationDetailData?.created_at)
            }}</ion-text>
          </div>
        </div>
        <div class="ion-text-end">
          <span class="process-item">
            <ion-button
              class="status"
              size="small"
              :color="
                quotationDetailData?.quotation_status === QUOTATION_STATUSSES.REJECTED
                  ? 'danger'
                  : quotationDetailData?.quotation_status === QUOTATION_STATUSSES.REQUESTED
                  ? 'light'
                  : 'primary'
              "
            >
              <span class="mr-2 ml-1 ion-text-capitalize">{{ quotationDetailData?.quotation_status }}</span>
              <ion-icon :icon="changeIcon(quotationDetailData?.quotation_status)"></ion-icon>
            </ion-button>
          </span>
        </div>
      </div>
      <ion-grid v-if="quotationDetailData?.price != null" class="mx-3">
        <ion-row>
          <ion-col size="6" v-if="quotationDetailData?.quotedBy != null">
            <div class="fs-2 fw-600 mb-1">{{ $t('quotedBy') }}</div>
            <div class="fs-2">
              {{ quotationDetailData?.quotedBy.first_name }} {{ quotationDetailData?.quotedBy.last_name }}
            </div>
          </ion-col>
          <ion-col size="6" v-if="quotationDetailData?.approver != null">
            <div class="fs-2 fw-600 mb-1">
              {{
                quotationDetailData?.quotation_status === QUOTATION_STATUSSES.REJECTED
                  ? $t('rejectedBy')
                  : $t('approvedBy')
              }}
            </div>

            <div class="fs-2">
              {{ quotationDetailData?.approver.first_name }} {{ quotationDetailData?.approver.last_name }}
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>

      <ion-list lines="none" class="mt-3 mb-3 mx-3">
        <ion-item class="ion-no-padding">
          <ion-grid>
            <ion-row class="fw-600 fs-2 mb-2 ml-1">{{ $t('productInfo') }}</ion-row>
            <ion-row>
              <ion-col size="2" class="mr-4">
                <div>
                  <td-image
                    key="product"
                    style="width: 66px; height: auto"
                    alt="product"
                    :image="quotationDetailData?.requestor?.image"
                    :imagePath="IMAGE_PATH.PRODUCT"
                    :imageDefault="DEFAULT_PRODUCT_IMAGE"
                  ></td-image>
                </div>
              </ion-col>
              <ion-col size="9" class="d-flex align-center">
                <ion-label>
                  <div class="fs-2 mb-1 ion-text-wrap">{{ quotationDetailData?.sku_name }}</div>
                  <div v-if="quotationDetailData?.halal">
                    <ion-avatar style="height: 100%">
                      <ion-img style="width: 24px; height: 24px" src="/assets/images/halal.svg" alt="img" />
                    </ion-avatar>
                  </div>
                </ion-label>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>
      </ion-list>

      <div class="d-flex justify-space-between mx-5" v-if="quotationDetailData?.price">
        <div>
          <div v-if="!quotationDetailData.is_order_by_weight" class="fs-2 mb-2">
            {{ $t('approvedPrice') }}:
          </div>
          <div class="fs-2">{{ $t('approvedPrice') }} / {{ quotationDetailData?.uom }}:</div>
        </div>
        <div class="text-primary fw-600 fs-2">
          <div class="mb-2" v-if="!quotationDetailData.is_order_by_weight">
            {{
              quotationDetailData?.status
                ? `${priceFormatter(currencySymbol, displayPrice(quotationDetailData?.price))}`
                : `-`
            }}
          </div>
          <div>
            {{
              quotationDetailData?.status
                ? `${priceFormatter(currencySymbol, displayPrice(quotationDetailData?.unit_price))}`
                : `-`
            }}
          </div>
        </div>
      </div>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-button
              style="height: 2.3rem"
              @click="toProductDetail(quotationDetailData?.product_id)"
              expand="block"
              color="primary"
              fill="outline"
              class="text-capitalize mx-3"
              >{{ $t('view_product') }}</ion-button
            >
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
    <ion-item
      v-if="isOpenLoadingref"
      class="ion-align-items-center ion-justify-content-center mx-auto my-auto"
    >
      <ion-spinner name="circular" color="medium"></ion-spinner>
    </ion-item>
  </ion-page>
</template>

<script>
import { DEFAULT_PRODUCT_IMAGE, DEFAULT_USER_AVARTAR, IMAGE_PATH } from '@/modules/b2b/constants/index';
import { displayPrice } from '@/modules/b2b/services/libs/helper';
import { ACTIONS } from '@/modules/b2b/store/notification/actions';
import { QUOTATION_STATUSSES } from '@/modules/shared/constants';
import { useDateFormatter } from '@/usecases/global';
import { priceFormatter } from '@/utils/';
import {
  checkmarkCircleOutline,
  closeCircleOutline,
  closeOutline,
  homeOutline,
  paperPlaneOutline,
  repeatOutline
} from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('b2b/notification');
export default defineComponent({
  name: 'ModalQuotationDetailB2b',
  props: {
    id: {
      type: Number,
      default: 0,
      required: true
    },
    closeModal: {
      type: Function,
      default: () => {},
      required: true
    }
  },
  inject: ['$storage'],
  setup(props) {
    const { formatDateTime } = useDateFormatter();
    const user = ref(null);
    const router = useRouter();
    const isOpenLoadingref = ref(false);
    const setOpenLoading = (state) => (isOpenLoadingref.value = state);
    const image = ref(null);
    const avatarImage = ref(null);
    const toProductDetail = (productId) => {
      props.closeModal();
      router.push({
        path: `/b2b/main/product-detail/${productId}`
      });
    };
    return {
      checkmarkCircleOutline,
      formatDateTime,
      paperPlaneOutline,
      closeOutline,
      repeatOutline,
      closeCircleOutline,
      homeOutline,
      user,
      displayPrice,
      setOpenLoading,
      isOpenLoadingref,
      image,
      avatarImage,
      priceFormatter,
      DEFAULT_USER_AVARTAR,
      DEFAULT_PRODUCT_IMAGE,
      QUOTATION_STATUSSES,
      IMAGE_PATH,
      toProductDetail
    };
  },
  async mounted() {
    this.user = await this.$storage.getUser();
    await this.getData();
  },
  computed: {
    ...mapGetters(['quotationDetailData', 'status']),
    changeIcon() {
      return (value) => {
        if (value) {
          if (value.toUpperCase().includes(QUOTATION_STATUSSES.RECEIVED)) return checkmarkCircleOutline;
          else if (value.toUpperCase().includes(QUOTATION_STATUSSES.REJECTED)) return closeOutline;
          else if (value.toUpperCase().includes(QUOTATION_STATUSSES.REQUESTED)) return paperPlaneOutline;
          else if (value.toUpperCase().includes(QUOTATION_STATUSSES.PENDING_APPROVAL)) return repeatOutline;
          else return repeatOutline;
        }
      };
    },
    currencySymbol() {
      return this.user && this.user.currency_symbol;
    }
  },
  methods: {
    ...mapActions([ACTIONS.GET_VIEWS_QUOTATIONS_DETAIL]),
    async getData() {
      this.setOpenLoading(true);
      await this[ACTIONS.GET_VIEWS_QUOTATIONS_DETAIL]({
        id: this.id
      });
      if (this.status) {
        this.setOpenLoading(false);
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.fs-15 {
  font-size: 15px;
  line-height: 22px;
  color: #9e9e9e;
}
.fs-11 {
  font-size: 11px;
}
.green-color {
  color: #64ae0f;
}
.grey-color {
  color: #424242;
}
.process-item {
  width: auto;
  display: inline-block;
  & > ion-button {
    pointer-events: none;
  }
}
.status {
  font-size: 12px;
  --border-radius: 28px;
}
ion-spinner {
  width: 70px;
  height: 70px;
}
</style>
