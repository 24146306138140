import { ACTIONS as ACTIONS_NOTIFICATION } from '@/modules/b2b/store/notification/actions';
import { ACTIONS as ACTIONS_SALE_PAYMENT } from '@/modules/sale/store/payment/actions';
import ModalPaymentInvoiceHistoryDetail from '@/modules/shared/components/notifications/ModalPaymentInvoiceHistoryDetail';
import ModalQuotationDetailB2b from '@/modules/shared/components/notifications/ModalQuotationDetails/ModalQuotationDetailB2b';
import ModalQuotationDetailSale from '@/modules/shared/components/notifications/ModalQuotationDetails/ModalQuotationDetailSale';
import PriceUpdateDetail from '@/modules/shared/components/notifications/PriceUpdateDetail';
import { SUB_TYPE_NOTIFICATIONS } from '@/modules/shared/constants';
import { DEFAULT_APP_LOGO, IMAGE_PATH, NOTIFICATION_CATEGORY_ICON_PATH } from '@/modules/shared/constants/';
import { handleRandomBackground } from '@/modules/shared/utils';
import { setBadgeNumber } from '@/modules/shared/utils/badge';
import { modalController } from '@ionic/vue';
import { computed, ref, watch } from 'vue';
import { useConfig, useGetStorageData } from '..';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
const [
  TYPE_NEW_ORDER,
  TYPE_ORDER_CANCELLATION,
  TYPE_ORDER_ITEM_CANCELLATION,
  TYPE_ORDER_PROCESSED,
  TYPE_ORDER_UPDATED,
  TYPE_ORDER_RECEIVED
] = SUB_TYPE_NOTIFICATIONS.ORDER;

const [
  TYPE_DELIVERY_STARTED,
  TYPE_DELIVERY_COMPLETED,
  TYPE_DELIVERY_FAILED
] = SUB_TYPE_NOTIFICATIONS.ORDER_JOURNEY;

const [
  TYPE_PRODUCT_OOS,
  TYPE_PRODUCT_BACK_STOCKED,
  TYPE_PRODUCT_LOW_STOCK
] = SUB_TYPE_NOTIFICATIONS.PRODUCT_STOCK_UPDATE;

const [
  TYPE_PAYMENT_TRANSACTION_UPDATED,
  TYPE_BATCH_PAYMENT_APPROVED,
  TYPE_BATCH_PAYMENT_REJECTED
] = SUB_TYPE_NOTIFICATIONS.PAYMENT;

const [
  TYPE_PRICE_UPDATE,
  ,
  TYPE_PRODUCT_PRICE_UPDATED,
  TYPE_QUOTATION_UPDATED,
  TYPE_PRICE_CHANGED,
  TYPE_DIRECT_AND_LIMIT_PRICE_UPDATED
] = SUB_TYPE_NOTIFICATIONS.PRICE_UPDATE;

const [
  TYPE_NEW_QUOTATION,
  TYPE_QUOTATION_APPROVED,
  TYPE_QUOTATION_REJECTED
] = SUB_TYPE_NOTIFICATIONS.QUOTATION;

const [TYPE_CUSTOMER_APPROVAL] = SUB_TYPE_NOTIFICATIONS.CUSTOMER_APPROVAL;

export const useCardNotification = (props) => {
  const { id: notificationId, sub_type, read, payload, created_at, customer_name } = props.data;
  const { t, store, router, storePath } = useConfig();
  const { selectedCompany, selectedUser } = useGetStorageData();
  const STORE_MODULE_PATH = `${storePath}/notification`;
  const isReadSingleNotif = ref(read);
  dayjs.extend(relativeTime);
  dayjs.extend(utc);

  const ORDER_DETAIL_PATH = `/${storePath}/order/purchase/order-detail/`;
  const PRODUCT_DETAIL_PATH = `/${storePath}/main/product-detail/`;

  const imagePath = () => {
    if (
      SUB_TYPE_NOTIFICATIONS.PRODUCT_STOCK_UPDATE.includes(sub_type) ||
      SUB_TYPE_NOTIFICATIONS.PRICE_UPDATE.includes(sub_type) ||
      SUB_TYPE_NOTIFICATIONS.NEW_PRODUCT.includes(sub_type) ||
      (SUB_TYPE_NOTIFICATIONS.QUOTATION.includes(sub_type) && storePath === 'b2b') ||
      (SUB_TYPE_NOTIFICATIONS.ORDER.includes(sub_type) && storePath === 'b2b') ||
      (SUB_TYPE_NOTIFICATIONS.ORDER_JOURNEY.includes(sub_type) && storePath === 'b2b')
    ) {
      return IMAGE_PATH.PRODUCT;
    } else if (SUB_TYPE_NOTIFICATIONS.PAYMENT.includes(sub_type)) {
      return DEFAULT_APP_LOGO;
    } else {
      return IMAGE_PATH.CUSTOMER;
    }
  };

  const notifOptions = [
    {
      label: t('orders_and_invoices'),
      payload: 'ORDER',
      icon: NOTIFICATION_CATEGORY_ICON_PATH.BOX,
      types: [
        ...SUB_TYPE_NOTIFICATIONS.ORDER,
        ...SUB_TYPE_NOTIFICATIONS.ORDER_JOURNEY,
        ...SUB_TYPE_NOTIFICATIONS.PAYMENT
      ]
    },
    {
      label: t('quotation'),
      payload: 'QUOTATION',
      icon: NOTIFICATION_CATEGORY_ICON_PATH.PRICE_TAG,
      types: [...SUB_TYPE_NOTIFICATIONS.QUOTATION, TYPE_QUOTATION_UPDATED]
    },
    {
      label: t('info'),
      payload: 'INFO',
      icon: NOTIFICATION_CATEGORY_ICON_PATH.INFO,
      types: [...SUB_TYPE_NOTIFICATIONS.CUSTOMER_APPROVAL]
    },
    {
      label: t('products_updates'),
      payload: 'PRODUCT',
      icon: NOTIFICATION_CATEGORY_ICON_PATH.FRUITS,
      types: [...SUB_TYPE_NOTIFICATIONS.PRICE_UPDATE, ...SUB_TYPE_NOTIFICATIONS.PRODUCT_STOCK_UPDATE],
      productDetail: SUB_TYPE_NOTIFICATIONS.PRICE_UPDATE
    }
  ].filter((item) => item.types.includes(sub_type));

  const paymentInvoiceDetails = computed(() => store.getters[`sale/payment/paymentInvoiceDetails`]);

  const displayDateAndTime = (dateYMD) => {
    const date = dayjs.utc(dateYMD);
    return date.fromNow();
  };

  const onShowQuotationDetailB2b = async () => {
    const modal = await modalController.create({
      component: ModalQuotationDetailB2b,
      componentProps: {
        id: JSON.parse(payload)?.quotation_id ?? ``,
        closeModal: async () => {
          await modal.dismiss();
        }
      }
    });
    modal.present();
  };

  const onShowQuotationDetailSale = async () => {
    const modal = await modalController.create({
      component: ModalQuotationDetailSale,
      componentProps: {
        id: JSON.parse(payload)?.quotation_id ?? ``,
        closeModal: async () => {
          await modal.dismiss();
        }
      }
    });
    modal.present();
  };

  const onShowProductPriceUpdate = async () => {
    const { account_number } = await selectedCompany.value;
    const modal = await modalController.create({
      component: PriceUpdateDetail,
      componentProps: {
        skuId: JSON.parse(payload)?.sku_id ?? ``,
        subType: sub_type,
        createdAt: created_at || ``,
        accountNumber: account_number || ``,
        closeModal: async () => {
          await modal.dismiss();
        }
      }
    });
    modal.present();
  };

  const onShowModalPaymentInvoiceDetail = async () => {
    const { id: customerId } = await selectedCompany.value;
    const { currency_symbol: currencySymbol, tenant } = await selectedUser.value;
    const params = {
      customerId,
      paymentId: JSON.parse(payload)?.batch_payment_id ?? ``,
      tenantId: tenant.id
    };
    await store.dispatch(`sale/payment/${ACTIONS_SALE_PAYMENT.GET_PAYMENT_INVOICE_DETAILS}`, {
      params
    });
    const modal = await modalController.create({
      component: ModalPaymentInvoiceHistoryDetail,
      componentProps: {
        data: paymentInvoiceDetails.value,
        currencySymbol,
        closeModal: async () => {
          await modal.dismiss();
        }
      }
    });
    modal.present();
  };

  const onReadSingleNotif = async () => {
    const { id } = await selectedCompany.value;
    await store.dispatch(`${STORE_MODULE_PATH}/${ACTIONS_NOTIFICATION.UPDATE_SUB_BUYER}`, {
      notificationId,
      read: true
    });
    await setBadgeNumber(store, id);
    isReadSingleNotif.value = true;
  };

  const onTapNotification = async () => {
    await onReadSingleNotif();
    const { product_id: productId, order_id: orderId } = JSON.parse(payload);
    switch (sub_type) {
      case TYPE_NEW_ORDER:
      case TYPE_ORDER_CANCELLATION:
      case TYPE_ORDER_ITEM_CANCELLATION:
      case TYPE_ORDER_PROCESSED:
      case TYPE_ORDER_UPDATED:
      case TYPE_ORDER_RECEIVED:
      case TYPE_DELIVERY_STARTED:
      case TYPE_DELIVERY_COMPLETED:
      case TYPE_DELIVERY_FAILED:
        router.push(`${ORDER_DETAIL_PATH}${orderId}`);
        break;
      case TYPE_PRODUCT_OOS:
      case TYPE_PRODUCT_BACK_STOCKED:
      case TYPE_PRODUCT_LOW_STOCK:
        router.push(`${PRODUCT_DETAIL_PATH}${productId}`);
        break;
      case TYPE_PAYMENT_TRANSACTION_UPDATED:
        router.push(`/${storePath}/payment-transaction/${orderId}`);
        break;
      case TYPE_BATCH_PAYMENT_APPROVED:
      case TYPE_BATCH_PAYMENT_REJECTED:
        onShowModalPaymentInvoiceDetail();
        break;
      case TYPE_CUSTOMER_APPROVAL:
        router.push(`/${storePath}/select-customer`);
        break;
      case TYPE_PRICE_UPDATE:
      case TYPE_PRODUCT_PRICE_UPDATED:
      case TYPE_QUOTATION_UPDATED:
      case TYPE_PRICE_CHANGED:
      case TYPE_DIRECT_AND_LIMIT_PRICE_UPDATED:
        onShowProductPriceUpdate();
        break;
      case TYPE_NEW_QUOTATION:
        onShowQuotationDetailSale();
        break;
      case TYPE_QUOTATION_APPROVED:
      case TYPE_QUOTATION_REJECTED:
        onShowQuotationDetailB2b();
        break;
      default:
        router.push(`/${storePath}/main/home`);
        break;
    }
  };

  const renderInitialCustomerNameThumbnail = () => {
    const parts = customer_name?.split(' ') ?? ``;
    const customerNameInitial =
      parts && parts.length >= 1 ? parts[0]?.trim().split(' ')[0].toUpperCase()[0] : null;
    return {
      customerName: customerNameInitial,
      handleRandomBackground
    };
  };

  const isValidThumbnail = (url) => {
    const regex = /\.(jpeg|jpg|gif|png)|APP_LOGO$/i;
    return regex.test(url);
  };

  watch(
    props,
    (newValue) => {
      isReadSingleNotif.value = newValue.data.read;
    },
    {
      deep: true
    }
  );

  return {
    renderInitialCustomerNameThumbnail,
    isValidThumbnail,
    onTapNotification,
    imagePath,
    isReadSingleNotif,
    displayDateAndTime,
    notifOptions,
    IMAGE_PATH,
    SUB_TYPE_NOTIFICATIONS
  };
};
