import ModalQuotationDetailB2b from '@/modules/shared/components/notifications/ModalQuotationDetails/ModalQuotationDetailB2b';
import ModalQuotationDetailSale from '@/modules/shared/components/notifications/ModalQuotationDetails/ModalQuotationDetailSale';
import { DEFAULT_WEIGHT_UOM, QUOTATION_STATUSSES, QUOTED_PRICED_BY } from '@/modules/shared/constants';
import { IMAGE_PATH } from '@/modules/shared/constants/';
import { handleRandomBackground } from '@/modules/shared/utils';
import { priceFormatter } from '@/utils/';
import { modalController } from '@ionic/vue';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import {
  checkmarkCircleOutline,
  closeOutline,
  homeOutline,
  paperPlaneOutline,
  repeatOutline
} from 'ionicons/icons';
import { onMounted, ref } from 'vue';
import { useConfig, useGetStorageData } from '..';
import { useDateFormatter } from '../global/useDateFormat';

export const useCardQuotation = (props) => {
  dayjs.extend(relativeTime);
  dayjs.extend(utc);
  const { storePath } = useConfig();
  const { formatDateTime } = useDateFormatter();
  const { selectedUser } = useGetStorageData();
  const currency = ref(null);
  const priceLabel = ref('');
  const onShowQuotationDetailB2b = async () => {
    const modal = await modalController.create({
      component: ModalQuotationDetailB2b,
      componentProps: {
        id: props.data.id,
        closeModal: async () => {
          await modal.dismiss();
        }
      }
    });
    modal.present();
  };

  const onShowQuotationDetailSale = async () => {
    const modal = await modalController.create({
      component: ModalQuotationDetailSale,
      componentProps: {
        id: props.data.id,
        closeModal: async () => {
          await modal.dismiss();
        }
      }
    });
    modal.present();
  };

  const priceLabelQuotation = async () => {
    currency.value = await selectedUser.value;
    return props.data?.is_order_by_weight
      ? `${priceFormatter(
          currency.value.currency_symbol,
          props.data?.price / props.data?.weight
        )}/${DEFAULT_WEIGHT_UOM}`
      : `${priceFormatter(currency.value.currency_symbol, props.data?.price)}(${priceFormatter(
          currency.value.currency_symbol,
          props.data?.is_catch_weight ||
            props.data?.is_order_by_weight ||
            props.data?.priced_by === QUOTED_PRICED_BY.WEIGHT
            ? props.data?.price / props.data.weight
            : props.data?.price / props.data.unit_per_oom
        )}/${
          props.data?.is_catch_weight ||
          props.data?.is_order_by_weight ||
          props.data?.priced_by === QUOTED_PRICED_BY.WEIGHT
            ? DEFAULT_WEIGHT_UOM
            : props.data.uom
        })`;
  };

  const onTapQuotationDetail = () => {
    storePath !== `b2b` ? onShowQuotationDetailSale() : onShowQuotationDetailB2b();
  };
  const renderIconQuotation = () => {
    const upperCaseStatus = props.data.quotation_status.toUpperCase();
    if (upperCaseStatus === QUOTATION_STATUSSES.RECEIVED) {
      return checkmarkCircleOutline;
    } else if (upperCaseStatus === QUOTATION_STATUSSES.REJECTED) {
      return closeOutline;
    } else if (upperCaseStatus === QUOTATION_STATUSSES.REQUESTED) {
      return paperPlaneOutline;
    } else if (upperCaseStatus === QUOTATION_STATUSSES.PENDING_APPROVAL) {
      return repeatOutline;
    } else {
      return repeatOutline;
    }
  };
  const renderInitialCustomerNameThumbnail = () => {
    const parts = props.data?.customer_name?.split(' ') ?? ``;
    const customerNameInitial =
      parts && parts.length > 1 ? parts[0]?.trim().split(' ')[0].toUpperCase()[0] : null;

    return {
      customerName: customerNameInitial,
      handleRandomBackground
    };
  };

  const imagePath = () => (storePath === `b2b` ? IMAGE_PATH.PRODUCT : IMAGE_PATH.CUSTOMER);

  const formatDateAndTime = (createdAt) => {
    return formatDateTime(createdAt);
  };
  onMounted(async () => {
    priceLabel.value = await priceLabelQuotation();
  });
  return {
    formatDateAndTime,
    imagePath,
    IMAGE_PATH,
    priceLabel,
    onTapQuotationDetail,
    priceLabelQuotation,
    renderInitialCustomerNameThumbnail,
    homeOutline,
    renderIconQuotation
  };
};
