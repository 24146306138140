<template>
  <ion-page>
    <ion-header>
      <ion-toolbar class="toolbar-header">
        <div class="d-flex align-center justify-space-between">
          <ion-label class="fw-500 fs-3 pl-2">{{ $t('requestQuotationDetails') }}</ion-label>
          <ion-icon @click="closeModal" class="text-gray-500 fs-5 pr-2" :icon="closeCircleOutline"></ion-icon>
        </div>
      </ion-toolbar>
    </ion-header>
    <ion-content v-if="isCallFinish">
      <container paddingHorizontal class="pt-5 mx-2">
        <user-item :name="getUserName" :address="item.customer_name" :image="item.requestor.image" />
      </container>
      <container paddingHorizontal paddingVertical class="mx-2">
        <request-item :date="item.created_at" :quotationStatus="item.quotation_status" />
      </container>
      <container paddingHorizontal class="contain mx-2">
        <product-item :item="item" :key="item.id" />
        <div class="my-4" v-if="item.direct_price">
          <ion-label class="fs-2 fw-500 text-gray-800">{{ $t('support_information') }}:</ion-label>
          <br />
          <ion-text class="fs-2 fw-500 text-gray-800">{{ item.description || '-' }}</ion-text>
        </div>
      </container>
      <container paddingHorizontal class="contain">
        <ion-grid>
          <div class="mt-2 d-flex justify-space-between align-center">
            <ion-label class="fs-2 fw-500 text-gray-800">
              {{ $t('direct_price') }}
            </ion-label>

            <div class="ion-text-end">
              <ion-text v-if="!item.is_order_by_weight" color="text-primary fs-2 fw-500">
                {{
                  item.direct_price == 0
                    ? '-'
                    : priceFormatter(currencySymbol, displayPrice(item.direct_price))
                }}
              </ion-text>
              <ion-text class="ml-1 fs-2 fw-500">{{
                item.direct_price === 0
                  ? '-'
                  : `${!item.is_order_by_weight ? '(' : ''}${priceFormatter(
                      currencySymbol,
                      displayPrice(
                        calculatePriceOnWeight(
                          item.direct_price,
                          uom === DEFAULT_WEIGHT_UOM ? item.weight : item.unit_per_oom
                        )
                      )
                    )}/${uom}${!item.is_order_by_weight ? ')' : ''}`
              }}</ion-text>
            </div>
          </div>
          <div class="mt-2 d-flex justify-space-between align-center">
            <ion-label class="fs-2 fw-500 text-gray-800">
              {{ $t('limit_price') }}
            </ion-label>

            <div class="ion-text-end">
              <ion-text v-if="!item.is_order_by_weight" color="text-primary fs-2 fw-500">
                {{
                  item.limit_price == 0
                    ? '-'
                    : `${priceFormatter(currencySymbol, displayPrice(item.limit_price))}`
                }}
              </ion-text>
              <ion-text class="ml-1 fs-2 fw-500">
                {{
                  item.limit_price == 0
                    ? '-'
                    : `${!item.is_order_by_weight ? '(' : ''}${priceFormatter(
                        currencySymbol,
                        displayPrice(
                          calculatePriceOnWeight(
                            item.limit_price,
                            uom === DEFAULT_WEIGHT_UOM ? item.weight : item.unit_per_oom
                          )
                        )
                      )}/${uom}${!item.is_order_by_weight ? ')' : ''}`
                }}</ion-text
              >
            </div>
          </div>
          <div class="mt-2 d-flex justify-space-between align-center">
            <ion-label class="fs-2 fw-500 text-gray-800">
              {{ $t('last_quoted_price') }}
            </ion-label>

            <div class="ion-text-end">
              <ion-text v-if="!item.is_order_by_weight" color="text-primary fs-2 fw-500">
                {{
                  item.quotation_status === QUOTE_RECEIVED
                    ? `${priceFormatter(currencySymbol, displayPrice(item.price))}`
                    : '-.--'
                }}
              </ion-text>
              <ion-text class="ml-1 fs-2 fw-500">
                {{
                  item.quotation_status === QUOTE_RECEIVED
                    ? `${!item.is_order_by_weight ? '(' : ''}${priceFormatter(
                        currencySymbol,
                        displayPrice(
                          calculatePriceOnWeight(
                            item.price,
                            uom === DEFAULT_WEIGHT_UOM ? item.weight : item.unit_per_oom
                          )
                        )
                      )}/${uom}${!item.is_order_by_weight ? ')' : ''}`
                    : '-.--'
                }}</ion-text
              >
            </div>
          </div>
          <div class="my-2 d-flex justify-space-between align-center">
            <ion-label class="fs-2 fw-500 text-gray-800">
              {{ $t('total_new_price') }}
            </ion-label>

            <div class="ion-text-end">
              <ion-text
                v-if="!item.is_order_by_weight"
                :class="computedRef.isRejected ? 'text-gray-500' : 'text-warning-500'"
                class="fw-500 fs-2"
              >
                {{
                  computedRef.isRejected
                    ? currencySymbol + '-'
                    : priceFormatter(
                        currencySymbol,
                        displayPrice(
                          quotedTypeKey === QUOTED_PRICED_BY.WEIGHT &&
                            !item.is_order_by_weight &&
                            !item.is_catch_weight
                            ? totalNewPrice
                            : price
                        )
                      )
                }}
              </ion-text>
              <ion-text class="ml-1 fs-2 fw-500" :class="item.is_order_by_weight ? 'text-warning-500' : ''">
                {{
                  item.limit_price === 0 || !totalNewPrice || computedRef.isRejected
                    ? `${!item.is_order_by_weight ? '(' : ''}${currencySymbol}0.00/${uom}${
                        !item.is_order_by_weight ? ')' : ''
                      }`
                    : `${!item.is_order_by_weight ? '(' : ''}${priceFormatter(
                        currencySymbol,
                        displayPrice(
                          calculatePriceOnWeight(
                            totalNewPrice,
                            quotedTypeKey === QUOTED_PRICED_BY.WEIGHT &&
                              !item.is_catch_weight &&
                              !item.is_order_by_weight
                              ? isCalculateByWeight
                                ? item.weight
                                : item.unit_per_oom
                              : 1
                          )
                        )
                      )}/${uom}${!item.is_order_by_weight ? ')' : ''}`
                }}</ion-text
              >
            </div>
          </div>
        </ion-grid>
      </container>
      <container paddingHorizontal class="mx-1 pt-2">
        <ion-grid class="ion-no-padding">
          <ion-row>
            <ion-text class="fw-600 fs-2 text-gray-800">
              {{ $t('set_new_price') }}
            </ion-text>
          </ion-row>
          <ion-radio-group
            v-if="!item.is_order_by_weight && !item.is_catch_weight"
            class="ion-no-padding"
            :value="quotedTypeKey"
            mode="md"
          >
            <ion-row class="mt-4 mb-2">
              <ion-col>
                <div class="d-flex flex-row align-center" @click="selectQuoteType(QUOTED_PRICED_BY.WEIGHT)">
                  <ion-radio slot="start" :value="QUOTED_PRICED_BY.WEIGHT"></ion-radio>
                  <ion-label class="ml-2">{{ $t('total_price') }}</ion-label>
                </div>
              </ion-col>
              <ion-col>
                <div class="d-flex flex-row align-center" @click="selectQuoteType(QUOTED_PRICED_BY.OOM)">
                  <ion-radio slot="start" :value="QUOTED_PRICED_BY.OOM"></ion-radio>
                  <ion-label class="ml-2">{{ $t('unit_price') }} </ion-label>
                </div>
              </ion-col>
            </ion-row>
          </ion-radio-group>
          <div
            :class="
              pricedByOom || item.is_catch_weight || item.is_order_by_weight
                ? 'd-flex flex-row justify-space-between'
                : ''
            "
            class="quote-input align-center mt-2"
          >
            <ion-item
              :class="quotedTypeKey === QUOTED_PRICED_BY.OOM && isNotCalculateByWeight ? '' : 'mr-1'"
              class="bottom-line"
              lines="inset"
              :detail="false"
            >
              <ion-label>{{ currencySymbol }}</ion-label>
              <ion-input
                :class="
                  pricedByOom || item.is_catch_weight || item.is_order_by_weight
                    ? 'input-quote-item-kg'
                    : 'input-quote-item'
                "
                class="ion-text-right"
                :placeholder="computedRef.isRejected ? '-' : '0.00'"
                type="number"
                inputmode="decimal"
                :disabled="computedRef.isDisableBtn"
                v-model="totalNewPrice"
                @ionChange="changePrice"
              />
            </ion-item>
            <div v-if="item.is_catch_weight || item.is_order_by_weight">/{{ uom }}</div>
            <div v-if="pricedByOom" class="ml-2 select-uom d-flex flex-row" @click="openSelectUom(true)">
              <ion-text>/{{ uom }}</ion-text>
              <ion-icon :icon="isOpenUomRef ? chevronUpOutline : chevronDownOutline"></ion-icon>
            </div>
          </div>
        </ion-grid>
      </container>
    </ion-content>
    <ion-footer class="ion-no-border" v-if="isCallFinish">
      <ion-grid>
        <ion-row>
          <ion-col>
            <custom-button
              v-if="!computedRef.isDisableBtn"
              :disabled="isDisabledSubmit || computedRef.isDisableBtn"
              :class="color"
              expand="block"
              mode="ios"
              @click="onApprovePrice"
            >
              <ion-text class="ion-text-capitalize">
                {{ $t(text) }}
              </ion-text>
            </custom-button>
            <ion-button
              v-if="item.direct_price > 0 && item.quotation_status === 'requested'"
              size="small"
              expand="block"
              class="ml-2"
              fill="clear"
              color="danger"
              @click="onRejectQuotation"
            >
              <ion-label class="fs-2 fw-500">{{ $t('reject') }} </ion-label>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-footer>
    <ion-modal
      ref="modal"
      :initial-breakpoint="1"
      :breakpoints="[0, 0.5, 1]"
      mode="ios"
      css-class="bottom-sheet-modal-sm"
      :is-open="isOpenUomRef"
      @didDismiss="openSelectUom(false)"
    >
      <ion-page>
        <ion-header mode="md" class="ion-no-border">
          <ion-toolbar class="header-modal-update-price px-3">
            <ion-label class="fs-4 fw-bold" color="dark" slot="start">{{ $t('qoted_by') }}</ion-label>
            <ion-icon slot="end" :icon="closeOutline" @click="openSelectUom(false)"></ion-icon>
          </ion-toolbar>
        </ion-header>
        <ion-content>
          <ion-list class="mr-4">
            <ion-radio-group mode="md" :value="uom">
              <ion-item @click="chooseUom(DEFAULT_WEIGHT_UOM)">
                <ion-label>/{{ DEFAULT_WEIGHT_UOM }}</ion-label>
                <ion-radio slot="start" :value="DEFAULT_WEIGHT_UOM"></ion-radio>
              </ion-item>

              <ion-item v-if="item.uom !== DEFAULT_WEIGHT_UOM" @click="chooseUom(item.uom)">
                <ion-label>/{{ item.uom }}</ion-label>
                <ion-radio slot="start" :value="item.uom"></ion-radio>
              </ion-item>
            </ion-radio-group>
          </ion-list>
        </ion-content>
      </ion-page>
    </ion-modal>

    <ion-item v-if="!isCallFinish" class="ion-align-items-center ion-justify-content-center mx-auto my-auto">
      <ion-spinner name="circular" color="medium"></ion-spinner>
    </ion-item>
  </ion-page>
</template>
<script>
import CustomButton from '@/components/atoms/button/Button.vue';
import Container from '@/components/atoms/container/Container.vue';
import UserItem from '@/components/molecules/user-item/UserItem.vue';
import ProductItem from '@/components/product-item/ProductItem.vue';
import RequestItem from '@/components/request-item/RequestItem.vue';
import { displayPrice } from '@/modules/sale/services/libs/helper';
import { DEFAULT_WEIGHT_UOM, QUOTED_PRICED_BY, QUOTE_RECEIVED } from '@/modules/shared/constants/';
import {
  calculatePriceOnWeight,
  useChangePrice,
  useCheckElement,
  useLoadData,
  useSendRejectQuotation,
  useSendUpdatePrice
} from '@/usecases/notifications/';
import { priceFormatter } from '@/utils/';
import { alertController } from '@ionic/vue';
import {
  chevronDownOutline,
  chevronUpOutline,
  closeCircleOutline,
  closeOutline,
  homeOutline
} from 'ionicons/icons';
import { computed, defineComponent, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'ModalQuotationDetailSale',
  components: {
    RequestItem,
    ProductItem,
    CustomButton,
    Container,
    UserItem
  },
  props: {
    id: {
      type: Number,
      default: null,
      required: true
    },

    closeModal: {
      type: Function,
      default: () => {},
      required: true
    }
  },
  setup(props) {
    const { t } = useI18n();
    const totalNewPrice = ref(null);
    const {
      item,
      getUserName,
      isOrderByWeight,
      isCatchWeight,
      pricedBy,
      uomData,
      isCallFinish
    } = useLoadData(props.id);
    const { unitIsNull } = useCheckElement();
    const { updatePrice, unitPrice, price, color, text, isDisabledSubmit } = useChangePrice();
    const { submitUpdatePrice } = useSendUpdatePrice();
    const { submitRejectQuotation } = useSendRejectQuotation();
    const quotedTypeKey = ref(pricedBy.value);
    const uom = ref(
      isOrderByWeight.value || isCatchWeight.value || pricedBy.value === QUOTED_PRICED_BY.WEIGHT
        ? DEFAULT_WEIGHT_UOM
        : uomData.value
    );
    const selectQuoteType = (key) => {
      quotedTypeKey.value = key;
      if (key === QUOTED_PRICED_BY.WEIGHT) {
        uom.value =
          isOrderByWeight.value || isCatchWeight.value || pricedBy.value === QUOTED_PRICED_BY.WEIGHT
            ? DEFAULT_WEIGHT_UOM
            : item.value?.uom;
      }
    };
    const chooseUom = (selectedUom) => {
      uom.value = selectedUom;
    };
    const isOpenUomRef = ref(false);
    const openSelectUom = (params) => {
      isOpenUomRef.value = params;
    };
    const changePrice = (event) => {
      const newPriceValue = {
        value: event.target.value,
        weight:
          quotedTypeKey.value === QUOTED_PRICED_BY.WEIGHT && isNotOrderCatchWeight.value
            ? 1
            : isCalculateByWeight.value && uom.value === DEFAULT_WEIGHT_UOM
            ? item.value.weight
            : item.value.unit_per_oom,
        limitPrice: item.value.limit_price
      };
      let pattern = /(\d+\.\d\d)/g;
      let maxLength = /(\d{0,18})/g.exec(newPriceValue.value);
      if (maxLength.input.length > 18) {
        event.target.value = maxLength[1];
      }
      let regex = pattern.exec(newPriceValue.value);
      if (regex) {
        event.target.value = parseFloat(regex[1]);
      }
      updatePrice(newPriceValue);
    };
    const computedRef = reactive({
      isPendingAndApproval: computed(() => {
        return (
          item.value.quotation_status === 'pending approval' || item.value.quotation_status === 'received'
        );
      }),
      isRejected: computed(() => {
        return item.value.quotation_status === 'rejected';
      }),
      isDisableBtn: computed(() => {
        const data = item.value.quotation_status;
        return data === 'pending approval' || data === 'received' || data === 'rejected';
      })
    });

    const onApprovePrice = async () => {
      if (item.value.direct_price > 0 && item.value.direct_price < price.value) {
        const alert = await alertController.create({
          mode: 'ios',
          header: t('quote_price_great_direct_price_title'),
          message: t('quote_price_great_direct_price_body'),
          backdropDismiss: false,
          buttons: [
            {
              text: t('cancel')
            },
            {
              text: t('quote_price_great_direct_price_agree'),
              handler: () => {
                submitSetPrice();
              }
            }
          ]
        });
        return alert.present();
      } else {
        submitSetPrice();
      }
    };

    const submitSetPrice = () => {
      const value = {
        id: props.id,
        price: price.value,
        limitPrice: item.value.limit_price,
        unitPrice: unitPrice.value
      };
      return submitUpdatePrice(value, props.closeModal);
    };

    const onRejectQuotation = () => {
      const payload = {
        id: props.id
      };
      return submitRejectQuotation(payload, props.closeModal);
    };

    const isCalculateByWeight = computed(() => {
      return (
        item.value.priced_by === QUOTED_PRICED_BY.WEIGHT ||
        item.value.is_catch_weight ||
        item.value.is_order_by_weight ||
        uom.value === DEFAULT_WEIGHT_UOM
      );
    });

    const isNotCalculateByWeight = computed(() => {
      return (
        item.value.priced_by !== QUOTED_PRICED_BY.WEIGHT &&
        !item.value.is_catch_weight &&
        !item.value.is_order_by_weight
      );
    });

    const isNotOrderCatchWeight = computed(() => {
      return !item.value.is_catch_weight && !item.value.is_order_by_weight;
    });

    const isOrderCatchWeight = computed(() => {
      return item.value.is_catch_weight || item.value.is_order_by_weight;
    });

    const pricedByOom = computed(() => {
      return quotedTypeKey.value === QUOTED_PRICED_BY.OOM && isNotOrderCatchWeight.value;
    });

    watch(isCallFinish, () => {
      if (isCallFinish.value) {
        setTimeout(() => {
          if (computedRef.isPendingAndApproval) {
            totalNewPrice.value = displayPrice(
              item.value.price / (isCalculateByWeight.value ? item.value.weight : item.value.unit_per_oom)
            );
          }
          uom.value =
            isCalculateByWeight.value ||
            (item.value.priced_by === QUOTED_PRICED_BY.WEIGHT && uomData.value === DEFAULT_WEIGHT_UOM)
              ? DEFAULT_WEIGHT_UOM
              : uomData.value;
          quotedTypeKey.value =
            !item.value.is_catch_weight && !item.value.is_order_by_weight
              ? QUOTED_PRICED_BY.OOM
              : pricedBy.value;
        }, 100);
      }
    });
    watch(uom, (newVal) => {
      const newPriceValue = {
        value: totalNewPrice.value,
        weight: newVal === DEFAULT_WEIGHT_UOM ? item.value.weight : item.value.unit_per_oom,
        limitPrice: item.value.limit_price
      };
      updatePrice(newPriceValue);
    });
    watch(quotedTypeKey, (newVal) => {
      const newPriceValue = {
        value: Number(totalNewPrice.value),
        weight: newVal === QUOTED_PRICED_BY.WEIGHT ? 1 : item.value.unit_per_oom,
        limitPrice: item.value.limit_price
      };
      updatePrice(newPriceValue);
    });

    return {
      calculatePriceOnWeight,
      priceFormatter,
      isDisabledSubmit,
      color,
      text,
      item,
      isCatchWeight,
      isOrderByWeight,
      uomData,
      pricedBy,
      getUserName,
      DEFAULT_WEIGHT_UOM,
      QUOTED_PRICED_BY,
      QUOTE_RECEIVED,
      unitIsNull,
      closeOutline,
      chevronDownOutline,
      chevronUpOutline,
      homeOutline,
      displayPrice,
      quotedTypeKey,
      selectQuoteType,
      uom,
      chooseUom,
      isOpenUomRef,
      openSelectUom,
      changePrice,
      closeCircleOutline,
      price,
      submitSetPrice,
      computedRef,
      totalNewPrice,
      isCallFinish,
      onRejectQuotation,
      onApprovePrice,
      isCalculateByWeight,
      isNotCalculateByWeight,
      isNotOrderCatchWeight,
      isOrderCatchWeight,
      pricedByOom
    };
  },
  data() {
    return {
      user: null
    };
  },
  inject: ['$storage'],
  async mounted() {
    this.user = await this.$storage.getUser();
  },
  computed: {
    currencySymbol() {
      return this.user ? this.user.country.currency_symbol : '';
    }
  }
});
</script>
<style lang="scss" scoped>
.header {
  ion-icon {
    color: black;
    font-size: 20px;
    background-color: #989aa226;
    padding: 5px;
    border-radius: 20px;
  }

  ion-title {
    font-size: 20px;
    font-weight: 500;
  }

  ion-toolbar {
    border-bottom: 0.5px solid #9794941a;
  }
}

.contain {
  border-bottom: 5px solid var(--ion-color-light);
}

ion-item {
  --padding-end: 0px;
  --inner-padding-end: 0px;
  --border-color: transparent;
}

ion-text {
  font-size: 16px;
}

h5,
h6,
p {
  margin: 0px;
}

h5 {
  font-size: 16px;
  font-weight: 600;
}

h6 {
  font-size: 12px;
  font-weight: 700;
}

p {
  font-size: 16px;
}

.button-warning {
  --background: var(--ion-color-button-warning);
  color: white;
}

.button-primary {
  --background: var(--ion-color-button-primary);
  color: white;
}

ion-radio-group {
  overflow: visible;
  white-space: nowrap;
}

.select-uom {
  width: 90px;
  height: 42px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  gap: 8px;
  border: 1px solid var(--Grey-300, #e0e0e0);
  background: var(--Grey-50, #fafafa);
}

.input-quote-item {
  width: calc(100vw - 9rem);
}
.input-quote-item-kg {
  width: calc(100vw - 7rem);
}

.bottom-line {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.quote-input {
  --padding-top: 2px;
  --padding-start: 0px;
  --padding-end: 0px;
  --padding-bottom: 2px;
  font-size: 17px;
  line-height: 20px;
  text-align: right;
}

.header-modal-update-price {
  border-bottom: 1px solid #0000001a;
  & > ion-icon {
    font-size: 20px;
    color: #92949c;
    background: #989aa226;
    padding: 5px;
    border-radius: 20px;
  }
}

ion-spinner {
  width: 70px;
  height: 70px;
}
</style>
