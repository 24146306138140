<template>
  <ion-row>
    <ion-col size="12" @click="toggleCollapse">
      <div class="d-flex align-center">
        <ion-col size="11" class="d-flex align-center">
          <div class="d-flex align-center promotion">
            <ion-icon
              :class="[computedRef.isDiscount ? 'discount' : 'not-discount']"
              :icon="computedRef.isDiscount ? caretDownOutline : caretUpOutline"
            />
            <ion-text
              :class="[
                computedRef.isDiscount ? 'text-success-500' : 'text-error-500',
                ' mx-1 font-style-chip'
              ]"
              >{{ computedRef.percentPrice }}</ion-text
            >
          </div>
          <ion-text class="font-style-chip">{{ computedRef.priceChange }}</ion-text>
        </ion-col>
        <ion-col size="1" class="">
          <div class="d-flex">
            <div :class="['toggle-icon', isExpanded ? 'rotate-180' : '']">
              <ion-icon :icon="chevronDownOutline" />
            </div>
          </div>
        </ion-col>
      </div>
    </ion-col>
    <div v-if="isExpanded">
      <transition-expand v-for="(id, index) in item" :key="index">
        <ion-col size="12" class="d-flex flex-column">
          <ion-text class="font-style-chip text-gray-700">{{ item[index].name }}</ion-text>
        </ion-col>
      </transition-expand>
      <ion-button
        :disabled="item.length >= totalCustomer"
        class="expand-btn"
        @click="$emit('loadMore', item.length, 1)"
        expand="block"
        fill="clear"
        >Load more
      </ion-button>
    </div>
  </ion-row>
</template>

<script>
import { displayPrice } from '@/modules/b2b/services/libs/helper';
import { priceFormatter } from '@/utils/';
import { caretDownOutline, caretUpOutline, chevronDownOutline } from 'ionicons/icons';
import { computed, defineAsyncComponent, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
const TransitionExpand = defineAsyncComponent(async () => {
  return import('@/modules/b2b/views/shared/TransitionExpand.vue');
});
export default {
  components: {
    TransitionExpand
  },
  props: {
    item: {
      type: Object,
      default: () => {},
      required: true
    },
    prev_price: {
      type: Number,
      default: 0,
      required: false
    },
    currencySymbol: {
      type: String,
      default: '',
      required: false
    },
    current_price: {
      type: Number,
      default: 0,
      required: false
    },
    totalCustomer: {
      type: Number,
      default: 0,
      required: true
    }
  },
  setup(props) {
    const { t } = useI18n();
    const isExpanded = ref(false);
    const space = ref(' ');
    const computedRef = reactive({
      isDiscount: computed(() => {
        return props.prev_price > props.current_price;
      }),
      priceChange: computed(() => {
        return (
          t('from') +
          space.value +
          priceFormatter(props.currencySymbol, displayPrice(props.prev_price)) +
          space.value +
          t('wallet.to') +
          space.value +
          priceFormatter(props.currencySymbol, displayPrice(props.current_price))
        );
      }),
      percentPrice: computed(() => {
        let a, b;
        props.prev_price > props.current_price
          ? ((a = props.prev_price), (b = props.current_price))
          : ((a = props.current_price), (b = props.prev_price));
        return (((a - b) / (b ? b : 1)) * 100).toFixed(1) + '%';
      })
    });
    //functions
    const toggleCollapse = () => (isExpanded.value = !isExpanded.value);
    return {
      space,
      computedRef,
      isExpanded,
      toggleCollapse,
      caretUpOutline,
      caretDownOutline,
      chevronDownOutline,
      t
    };
  }
};
</script>
<style lang="scss" scoped>
ion-row {
  & > ion-col > div {
    & > ion-col:nth-child(1) {
      ion-icon {
        padding: 2px;
        border-radius: 20px;
        color: var(--ion-color-text-white);
      }
      & > .promotion {
        min-width: 60px;
      }
    }
  }
  & > div {
    margin-left: 65px;
  }
}
.toggle-icon {
  transition: transform 0.3s ease;
}
.rotate-180 {
  transform: rotate(180deg);
}
.toggle-icon {
  & > ion-icon {
    padding-top: 1.5px;
    font-size: 20px;
    color: var(--ion-color-text-gray-700);
  }
}
.discount {
  background-color: var(--ion-color-text-success-500);
}
.not-discount {
  background-color: var(--ion-color-text-error-500);
}

.expand-btn {
  margin-top: 12px;
  border: 1.5px solid #00676a;
  border-radius: 8px;
  height: 36px;
  width: 100%;
  text-transform: none;
}
</style>
