import { ACTIONS } from '@/modules/b2b/store/notification/actions';
import { useBackButton } from '@ionic/vue';
import { chevronBackOutline } from 'ionicons/icons';
import { computed, onMounted, ref, watch } from 'vue';
import { useConfig } from '..';

const useProductUpdateNotification = () => {
  const { store, router, storePath } = useConfig();
  const STORE_MODULE_PATH = `${storePath}/notification`;
  const isDisabledLoadMore = ref(false);
  const isLoading = ref(false);
  const notificationsList = ref([]);
  const badgeHeader = ref(null);
  const setBadgeHeaderCount = (state) => (badgeHeader.value = state);
  const totalCountNotifications = computed(() => store.getters[`${STORE_MODULE_PATH}/totalCount`]);
  const allNotifications = computed(() => store.getters[`${STORE_MODULE_PATH}/notificationsLists`]);
  const notificationBadges = computed(() => store.getters[`${STORE_MODULE_PATH}/notificationsBadges`]);
  useBackButton(10, () => {
    router.back();
  });
  const fetchData = async () => {
    isLoading.value = true;
    await store.dispatch(`${STORE_MODULE_PATH}/${ACTIONS.GET_NOTIFICATIONS}`, {
      params: {
        offset: 0,
        isIncludeTotalCount: true,
        notificationCategory: 'PRODUCT'
      }
    });
    setBadgeHeaderCount(notificationBadges.value.productCount);

    notificationsList.value = allNotifications.value.data;
    isLoading.value = false;
  };

  const loadMore = async (e) => {
    if (totalCountNotifications.value === allNotifications.value.data.length) {
      isDisabledLoadMore.value = true;
      return;
    }
    await store.dispatch(`${STORE_MODULE_PATH}/${ACTIONS.LOAD_MORE_NOTIFICATION}`, {
      params: {
        offset: allNotifications.value.data.length,
        isIncludeTotalCount: false,
        notificationCategory: 'PRODUCT'
      },
      onFinish: async (data) => {
        notificationsList.value = [...notificationsList.value, ...data.data];
        e.target.complete();
      }
    });
  };

  watch(
    notificationBadges,
    (newValue) => {
      const { infoCount, orderCount, productCount, quotationCount } = newValue;
      if (!infoCount && !orderCount && !productCount && !quotationCount) {
        notificationsList.value = notificationsList.value.map((notif) => {
          return {
            ...notif,
            read: true
          };
        });
      }
      setBadgeHeaderCount(newValue.productCount);
    },
    {
      deep: true
    }
  );

  onMounted(() => {
    fetchData();
  });
  return {
    loadMore,
    fetchData,
    isLoading,
    isDisabledLoadMore,
    notificationsList,
    chevronBackOutline,
    badgeHeader
  };
};

export { useProductUpdateNotification };
