import { ACTIONS } from '@/modules/b2b/store/notification/actions';
import { NOTIFICATION_CATEGORY_ICON_PATH } from '@/modules/shared/constants/';
import { useBackButton } from '@ionic/vue';
import { chevronBackOutline, chevronForwardOutline } from 'ionicons/icons';
import { computed, onMounted, ref, watch } from 'vue';
import { useConfig } from '..';

const useNotificationAllPage = () => {
  const { t, store, router, isMerchantUser, storePath } = useConfig();
  const STORE_MODULE_PATH = `${storePath}/notification`;

  const isDisabledLoadMore = ref(false);
  const isLoading = ref(false);
  const notificationsList = ref([]);
  const notifOptions = ref(null);

  const setNotifOptions = (updatedBadge) => {
    notifOptions.value = [
      {
        name: 'ORDERS_AND_INVOICES',
        label: t('orders_and_invoices'),
        icon: NOTIFICATION_CATEGORY_ICON_PATH.BOX,
        latestMessage: updatedBadge.orderLatestMessage || t('no_new_unread_notif'),
        badgeNumber: updatedBadge.orderCount || '',
        handler: () =>
          isMerchantUser
            ? router.push({ name: 'B2bNotifOrderAndInvoicesList' })
            : router.push({ name: 'SaleNotifOrderAndInvoicesList' })
      },
      {
        name: 'QUOTATIONS_UPDATES',
        label: t('quotation'),
        icon: NOTIFICATION_CATEGORY_ICON_PATH.PRICE_TAG,
        latestMessage: updatedBadge.quotationLatestMessage || t('no_new_unread_notif'),
        badgeNumber: updatedBadge.quotationCount || '',
        handler: () =>
          isMerchantUser
            ? router.push({ name: 'B2bNotifQuotationsUpdates' })
            : router.push({ name: 'SaleNotifQuotationsUpdates' })
      },
      {
        name: 'PRODUCTS_UPDATES',
        label: t('products_updates'),
        icon: NOTIFICATION_CATEGORY_ICON_PATH.FRUITS,
        latestMessage: updatedBadge.productLatestMessage || t('no_new_unread_notif'),
        badgeNumber: updatedBadge.productCount || '',
        handler: () =>
          isMerchantUser
            ? router.push({ name: 'B2bNotifProductsUpdates' })
            : router.push({ name: 'SaleNotifProductsUpdates' })
      },
      {
        name: 'COMPANY_INFO',
        label: t('info'),
        icon: NOTIFICATION_CATEGORY_ICON_PATH.INFO,
        latestMessage: updatedBadge.infoLatestMessage,
        badgeNumber: updatedBadge.infoCount || '',
        handler: () =>
          isMerchantUser
            ? router.push({ name: 'B2bNotifCompanyInfo' })
            : router.push({ name: 'SaleNotifCompanyInfo' })
      }
    ];
  };

  const setNotificationListData = (state) => (notificationsList.value = state);
  const notificationBadges = computed(() => store.getters[`${STORE_MODULE_PATH}/notificationsBadges`]);
  const allNotifications = computed(() => store.getters[`${STORE_MODULE_PATH}/notificationsLists`]);
  const totalCountNotifications = computed(() => store.getters[`${STORE_MODULE_PATH}/totalCount`]);

  const fetchData = async () => {
    isLoading.value = true;
    await store.dispatch(`${STORE_MODULE_PATH}/${ACTIONS.RESET_QUERY_PARAMS}`);
    await store.dispatch(`${STORE_MODULE_PATH}/${ACTIONS.GET_NOTIFICATIONS}`, {
      params: {
        offset: 0,
        isIncludeTotalCount: true
      }
    });
    setNotificationListData(allNotifications.value.data);
    setNotifOptions(notificationBadges.value);
    isLoading.value = false;
  };

  const loadMore = async (e) => {
    if (totalCountNotifications.value === allNotifications.value.data.length) {
      isDisabledLoadMore.value = true;
      return;
    }
    await store.dispatch(`${STORE_MODULE_PATH}/${ACTIONS.LOAD_MORE_NOTIFICATION}`, {
      params: {
        offset: allNotifications.value.data.length,
        isIncludeTotalCount: false
      },
      onFinish: async (data) => {
        setNotificationListData([...notificationsList.value, ...data.data]);
        e.target.complete();
      }
    });
  };
  const handleScroll = () => {};

  const onTapBack = () => {
    const selectCompanyRoutePath = `/${storePath}/select-customer`;
    router.options.history.state.back === selectCompanyRoutePath
      ? router.replace(`/${storePath}/main/home`)
      : router.back();
  };

  useBackButton(10, () => {
    const selectCompanyRoutePath = `/${storePath}/select-customer`;
    router.options.history.state.back === selectCompanyRoutePath
      ? router.replace(`/${storePath}/main/home`)
      : router.back();
  });

  watch(
    notificationBadges,
    (newValue) => {
      const { infoCount, orderCount, productCount, quotationCount } = newValue;
      if (!infoCount && !orderCount && !productCount && !quotationCount) {
        notificationsList.value = notificationsList.value.map((notif) => {
          return {
            ...notif,
            read: true
          };
        });
      }
      setNotifOptions(newValue);
    },
    {
      deep: true
    }
  );

  onMounted(async () => {
    await fetchData();
  });
  return {
    onTapBack,
    loadMore,
    isLoading,
    isDisabledLoadMore,
    notificationBadges,
    fetchData,
    handleScroll,
    notificationsList,
    NOTIFICATION_CATEGORY_ICON_PATH,
    notifOptions,
    chevronBackOutline,
    chevronForwardOutline
  };
};

export { useNotificationAllPage };
