<template>
  <div class="d-flex justify-space-between mx-3 my-4">
    <ion-label class="fs-2 fw-600" slot="start">{{ $t('all_notifications') }}</ion-label>
    <ion-label slot="end" color="primary" class="fs-2 fw-600" @click="onReadAllNotifications">{{
      $t('markAsReadAll')
    }}</ion-label>
  </div>
</template>
<script>
import { useReadAllNotification } from '@/usecases/notifications';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AllNotifications',
  setup() {
    const { onReadAllNotifications } = useReadAllNotification();
    return {
      onReadAllNotifications
    };
  }
});
</script>
