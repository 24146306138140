<template>
  <ion-page>
    <ion-header>
      <ion-toolbar class="toolbar-header">
        <div class="d-flex align-center justify-space-between">
          <ion-label class="fw-500 fs-3 pl-2">{{ $t('product_price_update') }}</ion-label>
          <ion-icon @click="closeModal" class="text-gray-500 fs-5" :icon="closeCircleOutline"></ion-icon>
        </div>
      </ion-toolbar>
    </ion-header>
    <ion-content v-if="isShow">
      <ProductDetails
        :sku="items.sku"
        :user="user"
        :currencySymbol="computedRef.currencySymbol"
        @close-modal="closeModal"
      />

      <div class="padding"></div>
      <ion-grid class="ion-padding">
        <ion-row>
          <ion-col size="6">
            <ion-text class="font-style-card-subtitle text-black-700 text-uppercase">{{
              $t('price_change_log')
            }}</ion-text>
          </ion-col>
          <ion-col size="6">
            <ion-text class="d-flex justify-end font-style-card-subtitle fw-400 text-gray-700">{{
              formatDateTime(createdAt)
            }}</ion-text>
          </ion-col>
        </ion-row>
        <ion-row class="mt-2" v-if="[USER_TYPE_ID.BUYER, USER_TYPE_ID.SUB_BUYER].includes(user.user_type_id)">
          <ion-col size="1">
            <ion-icon class="info-icon" :icon="informationCircleOutline"></ion-icon>
          </ion-col>
          <ion-col>
            <ion-text class="price-info">{{ $t('price_log_info') }}</ion-text></ion-col
          >
        </ion-row>

        <div
          v-if="
            [USER_TYPE_ID.ADMIN, USER_TYPE_ID.SALE].includes(user.user_type_id) &&
            (items.directPriceCustomers?.totalCount > 0 || items.directPriceCustomers?.totalCount !== null)
          "
          class="ml-1 mt-4 mb-1 direct-capital text-gray-700"
        >
          {{ $t('direct_price') }}
        </div>
        <CompanyItem
          v-if="[USER_TYPE_ID.BUYER, USER_TYPE_ID.SUB_BUYER].includes(user.user_type_id)"
          @loadMore="loadMore"
          :item="items.customers"
          :prev_price="items.sku.previous_price"
          :currencySymbol="computedRef.currencySymbol"
          :current_price="items.sku.current_price"
          :totalCustomer="items.totalCount"
        />
        <CompanyItem
          v-if="
            [USER_TYPE_ID.ADMIN, USER_TYPE_ID.SALE].includes(user.user_type_id) &&
            (items.directPriceCustomers?.totalCount > 0 || items.directPriceCustomers?.totalCount !== null)
          "
          @loadMore="loadMore"
          :item="items.directPriceCustomers?.data"
          :prev_price="items.sku.previous_direct_price"
          :currencySymbol="computedRef.currencySymbol"
          :current_price="items.sku.direct_price"
          :totalCustomer="items.directPriceCustomers.totalCount"
        />

        <div
          v-if="
            [USER_TYPE_ID.ADMIN, USER_TYPE_ID.SALE].includes(user.user_type_id) &&
            (items.limitPriceCustomers?.totalCount > 0 || items.limitPriceCustomers?.totalCount !== null)
          "
          class="ml-1 mt-4 mb-1 direct-capital text-gray-700"
        >
          {{ $t('quotation_limit_price') }}
        </div>
        <DirectPriceCompany
          v-if="
            [USER_TYPE_ID.ADMIN, USER_TYPE_ID.SALE].includes(user.user_type_id) &&
            (items.limitPriceCustomers?.totalCount > 0 || items.limitPriceCustomers?.totalCount !== null)
          "
          @loadMore="loadMore"
          :item="items.limitPriceCustomers?.data"
          :totalCustomer="items.limitPriceCustomers.totalCount"
        />
      </ion-grid>
    </ion-content>
    <ion-modal
      mode="md"
      @didDismiss="handleCloseModal"
      :is-open="isOpen"
      :backdrop-dismiss="true"
      css-class="modal-filter-select-company"
    >
      <ModalSelectCompany
        @set-close-modal="handleCloseModal"
        @send-company-select="handleGetCompanySelect"
        :company="company"
        :cacheCompanySelect="cacheCompanySelect"
      />
    </ion-modal>
  </ion-page>
</template>

<script>
import { apolloClient } from '@/main';
import { IMAGE_PATH } from '@/modules/b2b/constants/index';
import { USER_TYPE_ID } from '@/modules/shared/constants';
import { useLoading } from '@/modules/shared/utils/';
import { getPriceUpdateDetails, saleGetPriceUpdateDetails } from '@/services/shared/graphql';
import { useDateFormatter } from '@/usecases/global';
import { chevronDownOutline, closeCircleOutline, informationCircleOutline } from 'ionicons/icons';
import { computed, inject, reactive, ref } from 'vue';
import CompanyItem from './CompanyItem/index.vue';
import DirectPriceCompany from './DirectPriceCompany/index.vue';
import ModalSelectCompany from './ModalSelectCompany/index.vue';
import ProductDetails from './ProductDetails/index.vue';

export default {
  name: 'PriceUpdateDetail',
  components: {
    ProductDetails,
    CompanyItem,
    DirectPriceCompany,
    ModalSelectCompany
  },
  props: {
    closeModal: {
      type: Function,
      default: () => ({}),
      required: true
    },
    skuId: {
      type: Number,
      default: 0,
      required: true
    },
    subType: {
      type: Number,
      default: 0,
      required: true
    },
    createdAt: {
      type: String,
      default: '',
      required: true
    },
    accountNumber: {
      type: String,
      default: ``,
      required: true
    }
  },
  setup(props) {
    const { showLoading } = useLoading();
    const { formatDateTime } = useDateFormatter();
    const isOpen = ref(false);
    const isShow = ref(false);
    const setShow = (state) => (isShow.value = state);
    const items = ref([]);
    const storage = inject('$storage');
    const company = ref([
      {
        id: -1,
        company: 'All',
        isChecked: true
      }
    ]);
    const user = ref(null);
    const customer = ref(null);
    const cacheCompanySelect = ref(null);
    const isSelectAllCompany = ref(true);
    const handleGetUserInfor = async () => (user.value = await storage.getUser());
    const computedRef = reactive({
      currencySymbol: computed(() => {
        return user.value && user.value.currency_symbol;
      }),
      isHiddenSelection: computed(() => {
        return items.value?.customers?.length === 0;
      })
    });
    const handleOpenModalSelectCompany = (params) => (isOpen.value = params);
    const handleCloseModal = () => (isOpen.value = false);
    const isMerchantUser = () => {
      return user.value?.user_type_id === 6 || user.value?.user_type_id === 12;
    };
    const handleGetPriceUpdateDetails = async (
      accountNumber = customer.value?.account_number,
      sku,
      subType,
      limit,
      limitPrice
    ) => {
      if (isMerchantUser()) {
        const { data } = await apolloClient.query({
          query: getPriceUpdateDetails,
          variables: {
            skuId: sku,
            subType: subType,
            accountNumber: accountNumber,
            limit: 10 + limit,
            offset: 0
          }
        });
        return data.getPriceUpdateDetails;
      } else {
        const { data } = await apolloClient.query({
          query: saleGetPriceUpdateDetails,
          variables: {
            skuId: sku,
            subType: subType,
            limitForDirectPrice: 10 + limit,
            offsetForDirectPrice: 0,
            limitForLimitPrice: 10 + limitPrice,
            offsetForLimitPrice: 0
          }
        });
        return data.saleGetPriceUpdateDetails;
      }
    };

    const loadMore = async (n, id) => {
      if (id === 1) {
        items.value = await handleGetPriceUpdateDetails(
          props.accountNumber,
          props.skuId,
          props.subType,
          n,
          0
        );
      } else {
        items.value = await handleGetPriceUpdateDetails(
          props.accountNumber,
          props.skuId,
          props.subType,
          0,
          n
        );
      }
    };

    const handleGetCompany = async () => {
      for (const i in user.value?.user_type_id === 6 || user.value?.user_type_id === 12
        ? items.value?.customers
        : items.value?.directPriceCustomers?.data) {
        for (const params of user.value?.user_type_id === 6 || user.value?.user_type_id === 12
          ? items.value?.customers[i]
          : items.value?.directPriceCustomers?.data[i]) {
          company.value.push({
            id: params.customer.id,
            company: params.customer.name,
            isChecked: false
          });
        }
      }
    };
    const handleGetData = async () => {
      showLoading(() => {
        Promise.allSettled([
          handleGetUserInfor(),
          handleGetPriceUpdateDetails(props.accountNumber, props.skuId, props.subType, 0, 0)
        ])
          .then(async (data) => {
            items.value = data[1].value;
            await handleGetCompany();
          })
          .catch(async (error) => {
            console.log(error);
          })
          .finally(async () => {
            setShow(true);
          });
      });
    };
    const handleGetCompanySelect = async (listCustomerSelect, filterChecker) => {
      handleCloseModal();
      cacheCompanySelect.value = listCustomerSelect;
      const isSelectAll = filterChecker.find((item) => item.id === -1);
      if (isSelectAll) {
        items.value = await handleGetPriceUpdateDetails(
          props.accountNumber,
          props.skuId,
          props.subType,
          0,
          0
        );
        isSelectAllCompany.value = true;
      } else {
        let data = [];
        for (const value of filterChecker) {
          if (!data.includes(value.id)) {
            data.push(value.id);
          }
        }
        items.value = await handleGetPriceUpdateDetails(props.accountNumber, props.skuId, props.subType, 0);
        isSelectAllCompany.value = false;
      }
    };
    return {
      isOpen,
      items,
      user,
      customer,
      company,
      storage,
      isShow,
      computedRef,
      isSelectAllCompany,
      cacheCompanySelect,
      handleOpenModalSelectCompany,
      handleCloseModal,
      handleGetCompanySelect,
      handleGetUserInfor,
      loadMore,
      chevronDownOutline,
      informationCircleOutline,
      formatDateTime,
      IMAGE_PATH,
      closeCircleOutline,
      handleGetData,
      USER_TYPE_ID
    };
  },

  async mounted() {
    this.user = await this.storage.getUser();
    this.customer = await this.storage.getSelectedCompany();
    await this.handleGetData();
  }
};
</script>

<style lang="scss" scoped>
ion-content {
  ion-grid {
    & > ion-row:nth-child(3) {
      & > ion-col > ion-button {
        --background: #ffffff;
        width: 100%;
        height: 44px;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        --border-radius: 8px;
      }
    }
  }
}
.btn-open-modal {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: none;

  & > ion-icon {
    font-size: 20px;
    color: var(--ion-color-text-gray-800);
  }
}
.padding {
  background-color: #d6d4d469;
  height: 8px;
  width: 100%;
}

.direct-capital {
  text-transform: uppercase;
}

.view-product-button {
  --border-width: 1.5px;
  --border-radius: 10px;
  text-transform: none;
}

.info-icon {
  height: 20px;
  width: 20px;
  color: #757575;
}

.price-info {
  line-height: 20px;
  font-size: 13px;
  color: #757575;
}
</style>
