<template>
  <ion-row>
    <ion-col size="12" @click="toggleCollapse">
      <div class="d-flex align-center">
        <ion-col size="1">
          <ion-icon class="info-icon" :icon="informationOutline" />
        </ion-col>
        <ion-col size="10" class="d-flex align-center">
          <div class="d-flex align-center promotion">
            <ion-text class="quote-info">{{ $t('following_company') }}</ion-text>
          </div>
        </ion-col>
        <ion-col size="1" class="">
          <div class="d-flex">
            <div :class="['toggle-icon', isExpanded ? 'rotate-180' : '']">
              <ion-icon :icon="chevronDownOutline" />
            </div>
          </div>
        </ion-col>
      </div>
    </ion-col>
    <div v-if="isExpanded">
      <transition-expand v-for="(id, index) in item" :key="index">
        <ion-col size="12" class="d-flex flex-column">
          <ion-text class="font-style-chip text-gray-700">{{ item[index].name }}</ion-text>
        </ion-col>
      </transition-expand>
      <ion-button
        :disabled="item.length >= totalCustomer"
        class="expand-btn"
        @click="$emit('loadMore', item.length, 2)"
        expand="block"
        fill="clear"
        >Load more
      </ion-button>
    </div>
  </ion-row>
</template>

<script>
import { chevronDownOutline, informationOutline } from 'ionicons/icons';
import { defineAsyncComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
const TransitionExpand = defineAsyncComponent(async () => {
  return import('@/modules/b2b/views/shared/TransitionExpand.vue');
});
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
      required: true
    },
    totalCustomer: {
      type: Number,
      default: 0,
      required: true
    }
  },
  components: {
    TransitionExpand
  },
  setup() {
    const { t } = useI18n();
    const isExpanded = ref(false);
    const toggleCollapse = () => (isExpanded.value = !isExpanded.value);
    return {
      isExpanded,
      toggleCollapse,
      informationOutline,
      chevronDownOutline,
      t
    };
  }
};
</script>
<style lang="scss" scoped>
ion-row {
  & > ion-col > div {
    & > ion-col:nth-child(1) {
      ion-icon {
        padding: 2px;
        border-radius: 20px;
        color: var(--ion-color-text-white);
      }
      & > .promotion {
        min-width: 60px;
      }
    }
  }
  & > div {
    margin-left: 65px;
  }
}
.toggle-icon {
  transition: transform 0.3s ease;
}
.rotate-180 {
  transform: rotate(180deg);
}
.toggle-icon {
  & > ion-icon {
    padding-top: 1.5px;
    font-size: 20px;
    color: var(--ion-color-text-gray-700);
  }
}
.discount {
  background-color: var(--ion-color-text-success-500);
}
.not-discount {
  background-color: var(--ion-color-text-error-500);
}

.expand-btn {
  margin-top: 12px;
  border: 1.5px solid #00676a;
  border-radius: 8px;
  height: 36px;
  width: 100%;
  text-transform: none;
}

.info-icon {
  height: 16px;
  width: 16px;
  background-color: #eb8c31;
}

.quote-info {
  line-height: 20px;
  font-size: 13px;
  color: #eb8c31;
}
</style>
