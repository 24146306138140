<template>
  <ion-header mode="md" class="ion-no-border">
    <ion-toolbar>
      <ion-title class="font-style-content-h5 text-gray-900" slot="start">{{ title }}</ion-title>
      <ion-icon slot="end" :icon="closeOutline" @click="$emit('close-modal')"></ion-icon>
    </ion-toolbar>
  </ion-header>
</template>

<script>
import { closeOutline } from 'ionicons/icons';
export default {
  props: ['title'],
  emits: ['close-modal'],
  setup() {
    return {
      closeOutline
    };
  }
};
</script>

<style src="./style.scss" lang="scss" scoped></style>
